import React from "react";
import { criterium } from "./BeoordelingsCriteria";
import InfoBlock from "@skryv/core-react-vo/src/components/base/InfoBlock/InfoBlock";
import PropTypes from "prop-types";
import { CriteriaTable } from "./CriteriaTable";
import { dossierStatus } from "../../constants/dossierStatus";

const tableColumns = [
  {
    key: "criterium",
    text: "Criterium",
  },
  {
    key: "beoordeling",
    text: "Beoordeling",
  },
];

export const CriteriumBlock = (props) => {
  return (
    <div>
      <div className="space">
        <h3 className="vl-step__title space--sm">{props.title}</h3>
        {props.alertMessage && props.alertMessage}
        <InfoBlock
          infoBlockData={[
            { label: "Status", value: dossierStatus[props.criterium.status] },
            {
              label: "Aangepast op",
              value: props.criterium.datumStatus,
            },
          ]}
          columnsNumber={2}
        />
      </div>
      {props.criterium.criteria && (
        <CriteriaTable
          tableColumns={tableColumns}
          criteria={props.criterium.criteria}
        />
      )}
    </div>
  );
};

CriteriumBlock.propTypes = {
  title: PropTypes.string.isRequired,
  criterium: criterium,
  alertMessage: PropTypes.element,
};
