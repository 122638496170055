import React, { useContext, useEffect, useState } from "react";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  defaultProps,
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";

import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";

import CustomInfoBlock from "./util/CustomInfoBlock";
import {
  createDossierInformationItem,
  createDossierInformationNestedItem,
} from "../util/createDossierInformationUtil";
import { retrieveInformatieFromHoofddossier } from "../services/hoofddossier";
import { retrieveContactInformatieFromGemeente } from "../services/gemeenteparameter";
import CustomContact from "./util/CustomContact";
import { DossierSectionLayout } from "./util/DossierSectionLayout";
import PropTypes from "prop-types";
import { KotlabelInfo } from "./util/KotlabelInfo";
import { ConformiteitsattestInfo } from "./util/ConformiteitsattestInfo";
import moment from "moment/moment";

export function flDossierWrapper() {
  function FlDossier(props) {
    const context = useContext(contextType);
    const [contactInformation, setContactInformation] = useState(undefined);
    const [infoFromHoofddossier, setInfoFromHoofddossier] = useState(undefined);
    const { dossierType, getFieldFromDocument } = props;

    useEffect(() => {
      if (!infoFromHoofddossier) {
        const hoofddossierId = getFieldFromDocument(dossierType, [
          "hoofddossierId",
        ]);
        retrieveInformatieFromHoofddossier(hoofddossierId, dossierType)
          .then((contactInfo) => {
            setInfoFromHoofddossier(contactInfo);
          })
          .catch(() => setInfoFromHoofddossier(undefined));
      }

      if (!contactInformation) {
        const nisCode = getFieldFromDocument(dossierType, ["adres", "nisCode"]);
        retrieveContactInformatieFromGemeente(nisCode, dossierType)
          .then((contactInfo) => {
            setContactInformation({
              title: contactInfo.dienst,
              address: {
                streetAndNumber:
                  !contactInfo.adres.straat && !contactInfo.adres.huisnummber
                    ? undefined
                    : `${contactInfo.adres.straat ? `${contactInfo.adres.straat} ` : ""}${contactInfo.adres.huisnummer ? contactInfo.adres.huisnummer : ""}`,
                zipCode: contactInfo.adres.postcode,
                city: contactInfo.adres.gemeente,
              },
              phone: contactInfo.contact.telefoonnummer,
              email: contactInfo.contact.emailadres,
              website: contactInfo.contact.website,
            });
          })
          .catch(() => setContactInformation(undefined));
      }
    }, [
      dossierType,
      infoFromHoofddossier,
      contactInformation,
      getFieldFromDocument,
    ]);

    const dossierInformation = getDossierInformation(
      props,
      infoFromHoofddossier,
      context,
      dossierType,
    );

    return (
      <PageContent contentName="dossier">
        <div className="dossier-page-header">
          <div className="dossier-page-header-title">
            {props.shouldShowTitle && (
              <div className="dossier-title vl-col--1-1">
                <Title level={1} title={props.dossier.label} />
              </div>
            )}
          </div>
        </div>
        {props.shouldShowDossierInformation && (
          <DossierSectionLayout
            icon="news"
            title="Dossier details"
            type="information"
          >
            <CustomInfoBlock infoBlockData={dossierInformation} />
          </DossierSectionLayout>
        )}
        {dossierType === "kotlabel" && (
          <KotlabelInfo getFieldFromDocument={getFieldFromDocument} />
        )}
        {dossierType === "conformiteitsattest" && (
          <ConformiteitsattestInfo
            shouldShowDownloads={props.shouldShowDownloads}
            downloads={props.downloads}
            downloadItems={props.downloadItems}
          />
        )}
        {props.shouldShowContactInformation && contactInformation && (
          <DossierSectionLayout icon="chat" title="Contact" type="contact">
            <CustomContact contactInformation={contactInformation} />
          </DossierSectionLayout>
        )}
      </PageContent>
    );
  }

  FlDossier.propTypes = {
    ...externalProps,
    ...internalProps,
    dossierType: PropTypes.oneOf(["kotlabel", "conformiteitsattest"])
      .isRequired,
  };
  FlDossier.defaultProps = defaultProps;

  return FlDossier;
}

const getContactInfo = (contactInfoFromHoofddossier, context) => {
  if (!contactInfoFromHoofddossier) return null;

  const contactInfoNestedValues = [];
  contactInfoNestedValues.push(
    createDossierInformationNestedItem(
      contactInfoFromHoofddossier.email,
      context.gettext("Email"),
      true,
    ),
  );
  if (contactInfoFromHoofddossier.phone) {
    contactInfoNestedValues.push(
      createDossierInformationNestedItem(
        contactInfoFromHoofddossier.phone,
        context.gettext("Telefoon"),
        true,
      ),
    );
  }

  return createDossierInformationItem(
    contactInfoNestedValues,
    context.gettext("Contactgegevens"),
    true,
  );
};

const getAddress = (getFieldFromDocument, context, dossierType) => {
  const street = getFieldFromDocument(dossierType, ["adres", "straat"]);
  const housenumber = getFieldFromDocument(dossierType, [
    "adres",
    "huisnummer",
  ]);
  const busnumber = getFieldFromDocument(dossierType, ["adres", "busnummer"]);
  const postalCode = getFieldFromDocument(dossierType, ["adres", "postcode"]);
  const municipality = getFieldFromDocument(dossierType, ["adres", "gemeente"]);
  const addressValue = `${street} ${housenumber} ${busnumber ? ` bus ${busnumber}` : ""}, ${postalCode} ${municipality}`;

  return createDossierInformationItem(addressValue, context.gettext("Adres"));
};

const getKamerNummer = (getFieldFromDocument, context) => {
  const kamerNummer = getFieldFromDocument("aanvraagKotlabel", [
    "internKamernummer",
  ]);

  if (!kamerNummer) {
    return null;
  }

  return createDossierInformationItem(
    kamerNummer,
    context.gettext("Intern kamernummer"),
    false,
    true,
  );
};

const getDossierInformation = (
  props,
  infoFromHoofddossier,
  context,
  dossierType,
) => {
  const dossierInformation = [];

  if (props.dossier) {
    dossierInformation.push(
      createDossierInformationItem(
        props.getFieldFromDocument(dossierType, [
          "status",
          "selectedOptionLabel",
          "nl",
        ]),
        context.gettext("Status"),
      ),
    );
    dossierInformation.push(
      createDossierInformationItem(
        moment(
          moment
            .utc(props.getFieldFromDocument(dossierType, ["datumStatus"]))
            .toDate(),
        ).format("DD-MM-YYYY"),
        context.gettext("Aangepast op"),
      ),
    );
    dossierInformation.push(
      getAddress(props.getFieldFromDocument, context, dossierType),
    );
    if (dossierType === "kotlabel") {
      const kamerNummer = getKamerNummer(props.getFieldFromDocument, context);
      kamerNummer && dossierInformation.push(kamerNummer);
    }
    const verdieping = props.getFieldFromDocument(dossierType, [
      "verdieping",
      "selectedOptionLabel",
      "nl",
    ]);
    const andereVerdieping =
      verdieping === "Andere"
        ? props.getFieldFromDocument(dossierType, ["andereVerdieping"])
        : undefined;
    if (andereVerdieping || verdieping) {
      dossierInformation.push(
        createDossierInformationItem(
          andereVerdieping ? andereVerdieping : verdieping,
          context.gettext("Verdieping"),
        ),
      );
    }
    const locatie = props.getFieldFromDocument(dossierType, ["locatie"]);
    if (locatie) {
      dossierInformation.push(
        createDossierInformationItem(
          locatie,
          context.gettext("Locatie"),
          false,
          true,
        ),
      );
    }
    if (infoFromHoofddossier) {
      const contactInfo = getContactInfo(
        infoFromHoofddossier.contactInformatie,
        context,
      );
      contactInfo && dossierInformation.push(contactInfo);
      dossierInformation.push(
        createDossierInformationItem(
          infoFromHoofddossier.aanvrager,
          context.gettext("Aanvrager"),
        ),
      );
    }
  }

  return dossierInformation;
};

export default coreDossierWrapper(flDossierWrapper());
