import React from "react";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import PropTypes from "prop-types";
import { Alert } from "./Alert";
import { CriteriumBlock } from "./CriteriumBlock";

import "./BeoordelingsCriteria.scss";

export const BeoordelingsCriteria = (props) => {
  const { vlaamsKotlabel, gemeentelijkeCriteria, kotlabelLink } =
    props.beoordeling;
  const alertMessages = mapStatusToAlertMessage(
    vlaamsKotlabel.status,
    gemeentelijkeCriteria.status,
    kotlabelLink,
  );

  return (
    <div className="dossier-beoordelingscriteria vl-col--1-1">
      <section className="vl-infoblock">
        <Title level={2} title="Beoordeling" iconClass="vl-vi-news" />
        {vlaamsKotlabel && (
          <div className="space--lg">
            <CriteriumBlock
              title="Vlaams Kotlabel"
              criterium={vlaamsKotlabel}
              alertMessage={alertMessages.vlaamsKotlabel}
            />
          </div>
        )}
        {gemeentelijkeCriteria && (
          <CriteriumBlock
            title="Gemeentelijke criteria studentenhuisvesting"
            criterium={gemeentelijkeCriteria}
            alertMessage={alertMessages.gemeentelijkeCriteria}
          />
        )}
      </section>
    </div>
  );
};

BeoordelingsCriteria.propTypes = {
  beoordeling: PropTypes.shape({
    kotlabelLink: PropTypes.string,
    vlaamsKotlabel: criterium,
    gemeentelijkeCriteria: criterium,
  }),
};

export const criterium = PropTypes.shape({
  status: PropTypes.string,
  datumStatus: PropTypes.string,
  criteria: criteria,
});

export const criteria = PropTypes.arrayOf(
  PropTypes.shape({
    criterium: PropTypes.string,
    beoordeling: PropTypes.oneOf(["ja", "nee", "gunstig", "ongunstig"]),
  }),
);

const mapStatusToAlertMessage = (
  statusVlaamsKotlabel,
  statusGemeentelijkeCriteria,
  linkKotlabel,
) => {
  const alertMessages = {
    vlaamsKotlabel: undefined,
    gemeentelijkeCriteria: undefined,
  };

  if (statusVlaamsKotlabel === "gunstig") {
    if (
      !statusGemeentelijkeCriteria ||
      statusGemeentelijkeCriteria === "gunstig"
    ) {
      alertMessages.vlaamsKotlabel = createAlertMessage(
        "Gunstig Vlaams kotlabel",
        "Uw kot voldoet aan alle criteria voor het Vlaams kotlabel. U kan onderstaande URL gebruiken als rechtstreekse link naar uw kot op de Vlaamse kotlabel website. Daar kan iedere burger de huidige toestand van dit kot opvragen.",
        "success",
        "check-circle",
        linkKotlabel,
      );
    }
    if (statusGemeentelijkeCriteria === "inBehandeling") {
      alertMessages.gemeentelijkeCriteria = createAlertMessage(
        "In behandeling",
        "De gemeentelijke criteria studentenhuisvesting worden nog beoordeeld door uw gemeente.",
        "small",
        "info-circle",
      );
    }
    if (statusGemeentelijkeCriteria === "gunstig") {
      alertMessages.gemeentelijkeCriteria = createAlertMessage(
        "Gunstige beoordeling gemeentelijke criteria studentenhuisvesting",
        "Uw kot voldoet aan alle criteria die de gemeente beoordeelt bij het uitreiken van een Vlaams kotlabel.",
        "success",
        "check-circle",
      );
    }
    if (statusGemeentelijkeCriteria === "ongunstig") {
      alertMessages.gemeentelijkeCriteria = createAlertMessage(
        "Ongunstige beoordeling gemeentelijke criteria studentenhuisvesting",
        "Uw kot voldoet niet aan alle criteria die de gemeente beoordeelt bij het uitreiken van een Vlaams kotlabel.",
      );
    }
  }
  if (statusVlaamsKotlabel === "ongunstig") {
    alertMessages.vlaamsKotlabel = createAlertMessage(
      "Ongunstig Vlaams kotlabel",
      "Uw kot voldoet niet aan alle criteria voor het Vlaams kotlabel.",
    );
  }
  if (statusVlaamsKotlabel === "vervallen") {
    alertMessages.vlaamsKotlabel = createAlertMessage(
      "Vervallen Vlaams kotlabel",
      "Eén van de bewijsstukken van het Vlaams kotlabel is vervallen. De beoordeling van de criteria wordt stopgezet.",
    );
    alertMessages.gemeentelijkeCriteria = createAlertMessage(
      "Gemeentelijke criteria studentenhuisvesting stopgezet",
      "Het Vlaams kotlabel is vervallen. Een gunstig Vlaams kotlabel is een voorwaarde voor de beoordeling van de gemeentelijke criteria studentenhuisvesting. De beoordeling is daarom stopgezet.",
    );
  }

  return alertMessages;
};

const createAlertMessage = (
  title,
  message,
  type = "error",
  icon = "info-circle",
  kotlabelLink,
) => {
  return (
    <div className="space--sm">
      <Alert
        title={title}
        message={message}
        type={type}
        icon={icon}
        kotlabelLink={kotlabelLink}
      />
    </div>
  );
};
