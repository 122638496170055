import React from "react";
import PropTypes from "prop-types";
import { DossierSectionLayout } from "./DossierSectionLayout";
import FileDisplay from "@skryv/core-react-vo/src/components/base/FileDisplay/FileDisplay";

export const ConformiteitsattestInfo = (props) => {
  const downloads = getDownloads(props.downloads, props.downloadItems);

  return (
    <>
      {props.shouldShowDownloads && downloads && downloads.length > 0 && (
        <DossierSectionLayout
          icon="file-download"
          title="Documents and Downloads"
          type="downloads"
        >
          <FileDisplay downloads={downloads} />
        </DossierSectionLayout>
      )}
    </>
  );
};

const getDownloads = (downloads, downloadItems) => {
  const mappedDownloads = [];
  if (downloads.length > 0) {
    for (let i = 0; i < downloadItems.length; i++) {
      mappedDownloads.push(downloads[i]);
    }
  }
  return mappedDownloads;
};

ConformiteitsattestInfo.propTypes = {
  shouldShowDownloads: PropTypes.bool,
  downloads: PropTypes.array,
  downloadItems: PropTypes.array,
};
