export default {
  VGW: "vgw",
  FBU: "fbu",
  OCMW: "fbu_ocmw_gegevens",
  HP: "huurpremieProd",
  HPWT: "huurpremieWachttijd",
  HS: "huursubsidie_",
  OVRE: "ovre-fo",
  OVRE_GAS: "ovre_gas",
  NW: "noodwoningen",
  JOST: "JOST",
  HL: "hypothecaire",
  VGWVHR: "vgwVerhaalrecht",
  GV: "geconventioneerde_verhuur",
  DTL: "dak_en_thuislozen",
  ACA: "aanvraag_conformiteitsattest",
  AKL: "aanvraag_kotlabel",
  CA: "conformiteitsattest",
  KL: "kotlabel",
};
