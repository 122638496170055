export default {
  title: "Wonen-Vlaanderen",
  email: "wonen@vlaanderen.be",
  address: {
    buildingName: "Herman Teirlinckgebouw",
    streetAndNumber: "Havenlaan 88, bus 40",
    zipCode: "1000",
    city: "Brussel",
    country: "België",
  },
  phone: "1700",
  lat: "50.8660706",
  long: "4.3500005",
  mapUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.1150061446383!2d4.350000500000001!3d50.86607060000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDUxJzU3LjkiTiA0wrAyMScwMC4wIkU!5e0!3m2!1sen!2sru!4v1538467543211",
};
