import React from "react";
import { connect } from "react-redux";
import { map, flatten, difference, get } from "lodash";
import moment from "moment";

import {
  fetchCurrentDossierMilestone,
  fetchDossierExecutableTasks,
} from "@skryv/core-react/src/core/store/actions/dossiers";
import { fetchAllDossierDefinitions } from "@skryv/core-react/src/core/store/actions/dosdefs";
import {
  selectDossierListCount,
  selectExecutableTasksForDossier,
} from "@skryv/core-react/src/core/store/selectors/dossiers";
import { fetchFlProfile, fetchOcmwDossier } from "../store/actions";
import {
  selectProfileTypeFromFlProfile,
  selectCurrentMilestoneWithDossierId,
  selectOcmwDossier,
  isLBAanvrager,
} from "../store/selectors";
import authentication from "@skryv/core-react/src/services/authentication";

import "@govflanders/vl-ui-core";
import "@govflanders/vl-ui-util";

import Skeleton from "react-loading-skeleton";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dashboardPageWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "./flDashboardWrapper";

import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";
import Contact from "@skryv/core-react-vo/src/components/base/Contact/Contact";
import CallToAction from "@skryv/core-react-vo/src/components/base/CallToAction/CallToAction";
import Table from "@skryv/core-react-vo/src/components/base/Table/Table";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import Button from "@skryv/core-react-vo/src/components/base/Button/Button";
import Pager from "@skryv/core-react-vo/src/components/base/Pager/Pager";

import dosdefKeys from "../constants/dossierDefinitionKeys";

import "@skryv/core-react-vo/src/pages/Dashboard/DashboardPage.scss";
import "./flDashboard.scss";

import {
  SHOW_HP_DOSSIER_BUTTON,
  SHOW_HP_WACHTTIJD_BUTTON,
  SHOW_HS_DOSSIER_BUTTON,
  HIDE_ALL_DOSSIER_BUTTONS,
  SHOW_HL_DOSSIER_BUTTON,
  SHOW_GV_DOSSIER_BUTTON,
  SHOW_VGWVHR_DOSSIER_BUTTON,
  SHOW_DTL_DOSSIER_BUTTON,
  SHOW_VLOK_DOSSIER_BUTTONS,
} from "../constants/featureFlags";
import { dossierDefinitionsToCallToAction } from "./dashboard-helpers";

class DashboardPage extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);
    this.props.fetchFlProfile();
    this.props.fetchOcmwDossier();
    this.props.fetchAllDossierDefinitions();
    this.state = {
      userRoles: [],
      shouldFetchAllDossierDetails: false,
      isLoadingPage: false,
      isLoadingUserProfile: false,
      isLoadingDossierDetails: false,
      searchQuery: "",
    };
  }

  componentDidMount() {
    this.setState({ isLoadingPage: true, isLoadingUserProfile: true });
    Promise.all([
      this.props.fetchFlProfile(),
      this.props.fetchOcmwDossier(),
      this.props.fetchAllDossierDefinitions(),
    ]).finally(() => {
      this.setState({ isLoadingUserProfile: false });
    });
    authentication.profile().then((profile) => {
      const userRoles = get(profile, ["teamMembership", "roles"]) || [];
      this.setState({ userRoles, isLoadingPage: false });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const dossierIds = map(this.props.dossiers, (dos) => dos.id);
    const nextDossierIds = map(nextProps.myDossiers, (dos) => dos.id);
    if (
      nextProps.myDossiers &&
      difference(nextDossierIds, dossierIds).length !== 0
    ) {
      this.fetchDossierDetails(nextProps);
    }
  }

  canCreateDossierOCMW() {
    return (
      this.state.userRoles.filter(
        (group) => group.key === "fbu_ocmw_dossierbehandelaar",
      ).length > 0
    );
  }

  shouldShowNWDossierButton() {
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    const isCurrentDateBeforeMay2024 = currentMonth < 5 && currentYear < 2025;
    if (isCurrentDateBeforeMay2024) return true;
    return false;
  }

  canCreateOCMWDossiers() {
    return this.props.isOCMW && this.props.isLBAanvrager;
  }

  fetchDossierDetails(nextProps) {
    this.setState({ isLoadingDossierDetails: true });
    let promises = flatten(
      map(nextProps.myDossiers, (dos) => {
        return [
          this.props.fetchDossierExecutableTasks(dos.id),
          this.props.fetchCurrentDossierMilestone(dos.id),
        ];
      }),
    );
    Promise.all(promises).finally(() => {
      this.setState({
        isLoadingDossierDetails: false,
        shouldFetchAllDossierDetails: false,
      });
    });
  }

  renderOCMW() {
    const title = () => {
      return (
        <div>
          <Title level={1} title="Formulierenloket" />
        </div>
      );
    };

    const creatableDossiers = () => {
      const dossierInformation = {
        dosdefKey: dosdefKeys.FBU,
        buttonLabels: {
          active: "Start FBU Dossier",
        },
      };

      const createDossierButton = (dossierInformation) => {
        return {
          labels: dossierInformation.buttonLabels || {
            active: this.context.gettext("Steps to start this dossier"),
          },
          action: () =>
            this.props.createNewDossier(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="button-group">
          {this.props.ocmwDossier && (
            <Button
              isSecondary={true}
              labels={{
                active: "OCMW Profiel",
              }}
              action={() => this.props.toDossier(this.props.ocmwDossier.id)}
            />
          )}
          {this.canCreateDossierOCMW() && (
            <Button
              labels={{
                active: createDossierButton(dossierInformation).labels.active,
              }}
              isBusy={this.props.creatingDossier[dossierInformation.dosdefKey]}
              action={createDossierButton(dossierInformation).action}
            />
          )}
          {this.canCreateOCMWDossiers() && this.shouldShowNWDossierButton() && (
            <Button
              labels={{
                active: "Start Noodwoningen",
              }}
              isBusy={this.props.creatingDossier[dosdefKeys.NW]}
              action={() => this.props.toDossierInformation(dosdefKeys.NW)}
            />
          )}
          {this.canCreateOCMWDossiers() && SHOW_DTL_DOSSIER_BUTTON && (
            <Button
              labels={{
                active: "Start Dak- en Thuislozen",
              }}
              isBusy={this.props.creatingDossier[dosdefKeys.DTL]}
              action={() => this.props.toDossierInformation(dosdefKeys.DTL)}
            />
          )}
        </div>
      );
    };

    return { title, creatableDossiers };
  }

  renderDefault() {
    const title = () => {
      return null;
    };

    const creatableDossiers = () => {
      const whitelistedDossierDefinitions = [];

      if (SHOW_HP_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HP);
      }

      if (SHOW_HP_WACHTTIJD_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HPWT);
      }

      if (SHOW_HS_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HS);
      }

      if (SHOW_GV_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.GV);
      }

      if (SHOW_HL_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.HL);
      }

      if (SHOW_VGWVHR_DOSSIER_BUTTON) {
        whitelistedDossierDefinitions.push(dosdefKeys.VGWVHR);
      }

      if (SHOW_VLOK_DOSSIER_BUTTONS) {
        whitelistedDossierDefinitions.push(
          dosdefKeys.AKL,
          dosdefKeys.ACA,
          dosdefKeys.CA,
          dosdefKeys.KL,
        );
      }

      const dossierDefinitions = dossierDefinitionsToCallToAction(
        this.props.dosdefs,
        whitelistedDossierDefinitions,
      );

      const createDossierColumns = { 1: 6, 2: 6, 3: 4, 4: 3, 5: 4, 6: 4 };

      const createDossierButton = (dossierInformation) => {
        return {
          iconClass: dossierInformation.buttonIcon,
          iconPosition: dossierInformation.buttonPosition,
          labels: dossierInformation.buttonLabels || {
            active:
              dossierInformation.startButtonLabel ||
              this.context.gettext("Steps to start this dossier"),
          },
          action: () =>
            this.props.toDossierInformation(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="dashboard-create-dossiers vl-col--1-1">
          <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
            {map(dossierDefinitions, (dossierInformation) => {
              return (
                <div
                  className={`vl-col--${
                    createDossierColumns[dossierDefinitions.length] || 6
                  }-12`}
                  key={dossierInformation.dosdefKey}
                >
                  <CallToAction
                    {...dossierInformation}
                    body={dossierInformation.description}
                    buttonInformation={createDossierButton(dossierInformation)}
                  ></CallToAction>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return { title, creatableDossiers };
  }

  renderLB() {
    const title = () => {
      return (
        <div className="dashboard-title vl-col--1-1">
          <Title
            level={1}
            title="Start hier je digitaal formulier van Wonen-Vlaanderen"
          />
        </div>
      );
    };

    const creatableDossiers = () => {
      const createDossierInformation = [];

      if (this.shouldShowNWDossierButton()) {
        createDossierInformation.push({
          dosdefKey: dosdefKeys.NW,
          subLabel: "Oproep noodwoningen",
          body: () => <p>Meer informatie</p>,
          buttonLabels: {
            active: "Selecteer",
          },
        });
      }

      if (SHOW_DTL_DOSSIER_BUTTON) {
        createDossierInformation.push({
          dosdefKey: dosdefKeys.DTL,
          subLabel: "Dak- en thuislozen",
          buttonLabels: {
            active: "Selecteer",
          },
        });
      }

      const createDossierColumns = { 1: 6, 2: 6, 3: 4, 4: 3, 5: 4, 6: 4 };

      const createDossierButton = (dossierInformation) => {
        return {
          iconClass: dossierInformation.buttonIcon,
          iconPosition: dossierInformation.buttonPosition,
          labels: dossierInformation.buttonLabels || {
            active: this.context.gettext("Steps to start this dossier"),
          },
          action: () =>
            this.props.toDossierInformation(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="dashboard-create-dossiers vl-col--1-1">
          <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
            {map(createDossierInformation, (dossierInformation) => {
              return (
                <div
                  className={`vl-col--${
                    createDossierColumns[createDossierInformation.length] || 6
                  }-12`}
                  key={dossierInformation.dosdefKey}
                >
                  <CallToAction
                    {...dossierInformation}
                    label={""}
                    buttonInformation={createDossierButton(dossierInformation)}
                  ></CallToAction>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return { title, creatableDossiers };
  }

  renderMyDossiers() {
    const ocmwDossierColumns = [
      {
        key: "label",
        text: "Dossier",
      },
      {
        key: "createdAt",
        text: "Created at",
        formatter: (cell) => {
          return moment(cell).format("DD-MM-YYYY");
        },
      },
      {
        key: "currentMilestone",
        text: "Status",
        formatter: (currentMilestone) => {
          return get(currentMilestone, "label");
        },
      },
      {
        key: "executableTasks",
        text: "Required actions",
      },
    ];

    const defaultDossierColumns = [
      {
        key: "label",
        text: "Dossier",
      },
      {
        key: "dossierDefinition.label",
        text: "Dossiertype",
      },
      {
        key: "createdAt",
        text: "Created at",
        formatter: (cell) => {
          return moment(cell).format("DD-MM-YYYY");
        },
      },
      {
        key: "currentMilestone",
        text: "Status",
        formatter: (currentMilestone) => {
          return get(currentMilestone, "label");
        },
      },
      {
        key: "executableTasks",
        text: "Required actions",
      },
    ];

    const myDossierColumns =
      this.props.isCitizen || this.props.isCompany
        ? defaultDossierColumns
        : ocmwDossierColumns;
    const dossiersLabel =
      this.props.isCitizen || this.props.isCompany
        ? "Mijn dossiers"
        : "Dossiers";

    const formattedColumn = myDossierColumns.map((col) => {
      if (col.key === "executableTasks") {
        return Object.assign({}, col, {
          formatter: (tasks) => {
            if (tasks) {
              const lengthOfTasks = tasks.length;
              return (
                lengthOfTasks > 0 &&
                tasks.map((task) => {
                  return (
                    <span
                      className="vl-pill vl-pill--clickable d-block"
                      key={task.id}
                    >
                      <span
                        className="vl-icon vl-vi vl-vi-alert-circle"
                        aria-hidden="true"
                      ></span>
                      {task.name}
                    </span>
                  );
                })
              );
            }
          },
        });
      }
      if (col.key === "currentMilestone") {
        return Object.assign({}, col, {
          formatter: (currentMilestone) => {
            return <span>{currentMilestone.label}</span>;
          },
        });
      } else {
        return col;
      }
    });

    const loadingColumns = myDossierColumns.map((col) => {
      return Object.assign({}, col, {
        formatter: () => {
          return <Skeleton width={50} height={15} />;
        },
      });
    });

    const handleFilterTableAction = (e) => {
      this.setState({ searchQuery: e.target.value });
      this.props.filterDossiersList(e.target.value);
    };

    return (
      <div className="dashboard-my-dossiers vl-col--1-1">
        <section className="vl-infoblock">
          <Title
            level={2}
            title={this.context.gettext(dossiersLabel)}
            iconClass="vl-vi-news"
          />
          <div className="vl-infoblock__content">
            <Table
              tableData={this.props.dossiers}
              tableColumns={
                this.props.loadingDossiers || this.state.isLoadingDossierDetails
                  ? loadingColumns
                  : formattedColumn
              }
              itemClickAction={(dossier) => this.props.toDossier(dossier.id)}
              filterTableAction={
                this.props.isCitizen || this.props.isCompany
                  ? false
                  : handleFilterTableAction
              }
              noDataText={
                this.props.loadingDossiers || this.state.isLoadingDossierDetails
                  ? ""
                  : this.context.gettext("No dossiers yet")
              }
            />
          </div>
        </section>
      </div>
    );
  }

  renderContactInformation() {
    if (!this.props.contactInformation) return null;
    return (
      <div className="dashboard-contact vl-col--1-1">
        <section className="vl-infoblock">
          <Title
            level={2}
            title={this.context.gettext("Contact")}
            iconClass="vl-vi-chat"
          />
          <Contact contactInformation={this.props.contactInformation} />
        </section>
      </div>
    );
  }

  renderCompany() {
    const title = () => {
      return null;
    };

    const creatableDossiers = () => {
      const whitelistedDossierDefinitions = [];

      if (SHOW_VLOK_DOSSIER_BUTTONS) {
        whitelistedDossierDefinitions.push(
          dosdefKeys.AKL,
          dosdefKeys.ACA,
          dosdefKeys.CA,
          dosdefKeys.KL,
        );
      }

      const dossierDefinitions = dossierDefinitionsToCallToAction(
        this.props.dosdefs,
        whitelistedDossierDefinitions,
      );

      const createDossierColumns = { 1: 6, 2: 6, 3: 4, 4: 3, 5: 4, 6: 4 };

      const createDossierButton = (dossierInformation) => {
        return {
          iconClass: dossierInformation.buttonIcon,
          iconPosition: dossierInformation.buttonPosition,
          labels: dossierInformation.buttonLabels || {
            active:
              dossierInformation.startButtonLabel ||
              this.context.gettext("Steps to start this dossier"),
          },
          action: () =>
            this.props.toDossierInformation(dossierInformation.dosdefKey),
        };
      };

      return (
        <div className="dashboard-create-dossiers vl-col--1-1">
          <div className="create-dossiers-grid vl-grid vl-grid--v-stretch">
            {map(dossierDefinitions, (dossierInformation) => {
              return (
                <div
                  className={`vl-col--${
                    createDossierColumns[dossierDefinitions.length] || 6
                  }-12`}
                  key={dossierInformation.dosdefKey}
                >
                  <CallToAction
                    {...dossierInformation}
                    body={dossierInformation.description}
                    buttonInformation={createDossierButton(dossierInformation)}
                  ></CallToAction>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return { title, creatableDossiers };
  }

  render() {
    const contentOCMW = (
      <div className="fl-dashboard-header-wrapper">
        {this.props.shouldShowTitle && this.renderOCMW().title()}
        {this.renderOCMW().creatableDossiers()}
      </div>
    );
    const contentCitizen = (
      <>
        {this.renderDefault().title()}
        {HIDE_ALL_DOSSIER_BUTTONS
          ? null
          : this.renderDefault().creatableDossiers()}
      </>
    );
    const contentLB = (
      <>
        {this.renderLB().title()}
        {this.renderLB().creatableDossiers()}
      </>
    );
    const contentCompany = (
      <>
        {this.renderCompany().title()}
        {this.renderCompany().creatableDossiers()}
      </>
    );
    const shouldHideMyDossiers =
      !this.props.isOCMW && this.props.dossiers.length === 0;
    return (
      <PageContent contentName="dashboard">
        {!this.state.isLoadingPage &&
          !this.state.isLoadingUserProfile &&
          this.props.isOCMW &&
          contentOCMW}
        {!shouldHideMyDossiers && this.renderMyDossiers()}
        {!shouldHideMyDossiers && (
          <Pager
            totalItemCount={this.props.totalDossiersCount}
            itemsPerPage={this.props.itemsPerPage}
            handleNextClick={(pageNumber) => {
              this.props.searchDashboardDossiers(
                this.state.searchQuery,
                pageNumber,
                this.props.itemsPerPage,
              );
              this.setState({ shouldFetchAllDossierDetails: true });
            }}
            handlePrevClick={(pageNumber) => {
              this.props.searchDashboardDossiers(
                this.state.searchQuery,
                pageNumber,
                this.props.itemsPerPage,
              );
              this.setState({ shouldFetchAllDossierDetails: true });
            }}
          />
        )}
        {!this.state.isLoadingPage &&
          !this.state.isLoadingUserProfile &&
          this.props.isCitizen &&
          contentCitizen}
        {!this.state.isLoadingPage &&
          !this.state.isLoadingUserProfile &&
          this.props.isCompany &&
          contentCompany}
        {!this.state.isLoadingPage &&
          !this.state.isLoadingUserProfile &&
          this.props.isLB &&
          contentLB}
        {this.props.shouldShowContactInformation &&
          this.renderContactInformation()}
      </PageContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let dossiers = [];
  if (ownProps.myDossiers) {
    [...ownProps.myDossiers].map((dossier) => {
      const currentMilestone = selectCurrentMilestoneWithDossierId(
        state,
        dossier.id,
      );
      const executableTasks = selectExecutableTasksForDossier(
        state,
        dossier.id,
      );
      dossiers.push({
        ...dossier,
        currentMilestone: {
          key: get(currentMilestone, ["key"]),
          label: get(currentMilestone, ["label"]),
        },
        executableTasks,
      });
    });
  }

  const ocmwDossier = selectOcmwDossier(state);
  const isCompany = selectProfileTypeFromFlProfile(state) === "company";
  const isOCMW = selectProfileTypeFromFlProfile(state) === "ocmw";
  const isLB = selectProfileTypeFromFlProfile(state) === "lb";
  const isCitizen = !isCompany && !isOCMW && !isLB;
  const totalDossiersCount = selectDossierListCount(state, "DASHBOARD");

  return {
    isCompany,
    isLBAanvrager: isLBAanvrager(state),
    isOCMW,
    isLB,
    isCitizen,
    ocmwDossier,
    dossiers,
    totalDossiersCount,
  };
};

const mapDispatchToProps = {
  fetchFlProfile,
  fetchOcmwDossier,
  fetchAllDossierDefinitions,
  fetchCurrentDossierMilestone,
  fetchDossierExecutableTasks,
};

export default dashboardPageWrapper(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage),
);
