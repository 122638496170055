export default {
  // milestones : refer to name field in BPMN milestones
  // to be used with getCurrentMilestoneLabel()
  DOSSIER_GEANNULEERD_OCMW: "dossier_geannuleerd_ocmw",
  BEGELEIDINGSOVEREENKOMST_INGEVULD_PAPIER:
    "begeleidingsovereenkomst_ingevuld_papier",
  WIJZIG_DE_BEGELEIDINGSOVEREENKOMST: "wijzig_de_begeleidingsovereenkomst",
  ONDERTEKEND_OP_PAPIER: "ondertekend_op_papier",
  BEGELEIDINGSOVEREENKOMST_INGEVULD_DIGITAAL:
    "begeleidingsovereenkomst_ingevuld_digitaal",
  HET_OCMW_HEEFT_ONDERTEKEND: "OCMW_heeft_ondertekend",
  ALLE_VERHUURDERS_HEBBEN_ONDERTEKEND: "alle_verhuurders_hebben_ondertekend",
  ALLE_HUURDERS_HEBBEN_ONDERTEKEND: "alle_huurders_hebben_ondertekend",
  OPGELADEN_BEGELEIDINGSOVEREENKOMST_NIET_OK:
    "opgeladen_begeleidingsovereenkomst_niet_ok",
  DIGITAAL_ONDERTEKEND: "digitaal_ondertekend",
  ADRESVALIDATIE_NEGATIEF: "adresvalidatie_negatief",
  DOSSIER_AFGEWEZEN: "dossier_afgewezen",
  NEGATIEVE_ADRESVALIDATIE_OVERSCHREVEN:
    "negatieve_adresvalidatie_overschreven",
  DOSSIER_HERSTART: "dossier_herstart",
  DOSSIER_GESTOPT_FBU_DIENSTHOOFD: "dossier_gestopt_fbu_diensthoofd",
  EERSTE_UITBETALING_UITGEVOERD: "eerste_uitbetaling_uitgevoerd",
  BEWIJS_STABIELE_WOONSITUATIE_GEWIJZIGD:
    "bewijs_stabiele_woonsituatie_gewijzigd",
  BEWIJS_STABIELE_WOONSITUATIE_GEANNULEERD:
    "bewijs_stabiele_woonsituatie_geannuleerd",
  STABIELE_WOONSITUATIE_BEWEZEN_PAPIER: "stabiele_woonsituatie_bewezen_papier",
  STABIELE_WOONSITUATIE_BEWEZEN_DIGITAAL:
    "stabiele_woonsituatie_bewezen_digitaal",
  VERKLARING_ONDERTEKEND_OP_PAPIER: "verklaring_ondertekend_op_papier",
  VERKLARING_DIGITAAL_ONDERTEKEND: "verklaring_digitaal_ondertekend",
  VERKLARING_NEGATIEF: "verklaring_negatief",
  OPGELADEN_VERKLARING_NIET_OK: "opgeladen_verklaring_niet_ok",
  AANVRAAG_AFGEWERKT: "aanvraag_afgewerkt",
  KLAAR_VOOR_ONDERTEKENING_DOOR_OCMW: "klaar_voor_ondertekening_door_ocmw",
  BETALING_GESTART: "betaling_gestart",
  // BETALING_GEIMPORTEERD: 'Betaling geïmporteerd',
  OCMW_GEGEVENS_ONTBREKEN: "ocmw_gegevens_ontbreken",
  HP_ONTVANGEN: "HP_ontvangstbevestiging",
  HS_ONTVANGEN: "HS_ontvangstbevestiging",
  //OVRE
  OVRE_FO_ONTVANGEN: "ovre_fo_ontvangstbevestiging",
  OVRE_FO_ONDERTEKENEN: "mbpSubmitted",
  VGW_DOSSIER_GEEXPORTEERD: "vgw_dossier_geexporteerd",
  VGW_AANVRAAG_INGEVULD: "vgw_aanvraag_ingevuld",
  VGW_AANVRAAG_GEANNULEERD: "vgw_aanvraag_geannuleerd",
  //JOST
  JOST_INGEDIEND: "jostIngediend",
  //VGWVHR
  VGWVHR_INGEDIEND: "vgwVerhaalrechtIngediend",
  VGWVHR_BESLIST: "vgwVerhaalrechtBeslist",
};
