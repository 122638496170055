export const createDossierInformationItem = (
  value,
  label,
  nested = false,
  truncate = false,
) => {
  return {
    value: value,
    label: label,
    nested: nested,
    truncate: truncate,
  };
};

export const createDossierInformationNestedItem = (
  value,
  label,
  truncate = false,
) => {
  return {
    value: value,
    label: label,
    truncate: truncate,
  };
};
