import { omit } from "lodash";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";
import milestoneKeys from "../constants/milestoneDefinitionKeys";
import profileTypes from "../constants/profileTypes";

export function getNotifications(
  milestoneKey,
  profileType,
  dossier,
  getFieldFromDocument,
  getMilestone,
) {
  if (!milestoneKey) return [];

  const genericNotificationCitizenOrCompany = {
    profileTypes: [profileTypes.CITIZEN, profileTypes.COMPANY],
    id: dossier.id,
    title: "Dossier in behandeling",
    message: `Het dossier met dossiernummer ${dossier.label} wordt momenteel behandeld.`,
    type: notificationTypes.SUCCESS,
  };
  const genericNotificationAllUsers = {
    profileTypes: [
      profileTypes.OCMW,
      profileTypes.CITIZEN,
      profileTypes.COMPANY,
    ],
    id: dossier.id,
    title: "Dossier in behandeling",
    message: `Het dossier met dossiernummer ${dossier.label} wordt momenteel behandeld.`,
    type: notificationTypes.SUCCESS,
  };
  const notifications = {
    [milestoneKeys.DOSSIER_GEANNULEERD_OCMW]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Dossier geannuleerd",
        message: `Het dossier met dossiernummer ${dossier.label} werd geannuleerd.`,
        type: notificationTypes.ERROR,
      },
    ],
    [milestoneKeys.OCMW_GEGEVENS_ONTBREKEN]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "OCMW gegevens ontbreken",
        message:
          "De gegevens van uw OCMW ontbreken. De behandeling van dit dossier kan pas verder wanneer deze zijn aangevuld. Contacteer uw algemeen directeur of een gedelegeerd persoon om dit te doen.",
        type: notificationTypes.ERROR,
      },
    ],
    [milestoneKeys.WIJZIG_DE_BEGELEIDINGSOVEREENKOMST]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Begeleidingsovereenkomst wijzigen",
        message: "U kunt nu de begeleidingsovereenkomst wijzigen.",
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.DOSSIER_HERSTART]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Begeleidingsovereenkomst controleren",
        message: getFieldFromDocument("fbu_negatieve_adresvalidatie", [
          "motivatie_negatieve_adresvaliatie",
          "value",
        ]),
        type: notificationTypes.WARNING,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.BEGELEIDINGSOVEREENKOMST_INGEVULD_DIGITAAL]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Begeleidingsovereenkomst ingevuld",
        message:
          "De begeleidingsovereenkomst is klaar voor ondertekening. U dient de huurder(s), de verhuurder(s) en de OCMW-ondertekenaar(s) te verwittigen dat ze moeten inloggen op het formulierenloket met hun eID. Nadat alle partijen digitaal hebben ondertekend, wordt de begeleidingsovereenkomst automatisch doorgestuurd naar het Fonds. U vindt de begeleidingsovereenkomst hieronder terug onder documenten en downloads.",
        type: notificationTypes.SUCCESS,
      },
      {
        profileTypes: [profileTypes.CITIZEN, profileTypes.COMPANY],
        id: dossier.id,
        title: "Begeleidingsovereenkomst ingevuld",
        message: "De begeleidingsovereenkomst is klaar voor ondertekening.",
        type: notificationTypes.SUCCESS,
      },
    ],
    [milestoneKeys.HET_OCMW_HEEFT_ONDERTEKEND]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Begeleidingsovereenkomst digitaal ondertekend",
        message:
          "Uw ondertekening werd opgeslagen. U vindt hieronder een overzicht van de ontbrekende ondertekeningen.",
        type: notificationTypes.SUCCESS,
      },
      {
        profileTypes: [profileTypes.CITIZEN, profileTypes.COMPANY],
        id: dossier.id,
        title: "Digitale ondertekening begeleidingsovereenkomst",
        message:
          "De begeleidingsovereenkomst wordt voorgelegd aan de partijen ter ondertekening. U vindt hieronder een overzicht van de ontbrekende ondertekeningen.",
        type: notificationTypes.SUCCESS,
      },
    ],
    [milestoneKeys.ALLE_HUURDERS_HEBBEN_ONDERTEKEND]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Digitale ondertekening begeleidingsovereenkomst",
        message:
          "De begeleidingsovereenkomst moet nog door een of meerdere partijen worden ondertekend. U vindt hieronder een overzicht van de ontbrekende ondertekeningen.",
        type: notificationTypes.SUCCESS,
      },
      {
        profileTypes: [profileTypes.CITIZEN, profileTypes.COMPANY],
        id: dossier.id,
        title: "Digitale ondertekening begeleidingsovereenkomst",
        message:
          "De begeleidingsovereenkomst wordt voorgelegd aan de partijen ter ondertekening. U vindt hieronder een overzicht van de ontbrekende ondertekeningen.",
        type: notificationTypes.SUCCESS,
      },
    ],
    [milestoneKeys.ALLE_VERHUURDERS_HEBBEN_ONDERTEKEND]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Digitale ondertekening begeleidingsovereenkomst",
        message:
          "De begeleidingsovereenkomst moet nog door een of meerdere partijen worden ondertekend. U vindt hieronder een overzicht van de ontbrekende ondertekeningen.",
        type: notificationTypes.SUCCESS,
      },
      {
        profileTypes: [profileTypes.CITIZEN, profileTypes.COMPANY],
        id: dossier.id,
        title: "Digitale ondertekening begeleidingsovereenkomst",
        message:
          "De begeleidingsovereenkomst wordt voorgelegd aan de partijen ter ondertekening. U vindt hieronder een overzicht van de ontbrekende ondertekeningen.",
        type: notificationTypes.SUCCESS,
      },
    ],
    [milestoneKeys.DIGITAAL_ONDERTEKEND]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Ondertekend digitaal",
        message: `Uw aanvraag kreeg volgend dossiernummer toegewezen: ${dossier.label}. (Gelieve dit dossiernummer steeds te vermelden in verdere communicatie over dit dossier.)`,
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.BEGELEIDINGSOVEREENKOMST_INGEVULD_PAPIER]: () => {
      const isOpgeladenOvereenkomstNOK =
        getMilestone("opgeladen_begeleidingsovereenkomst_niet_ok") &&
        !getMilestone("wijzig_de_begeleidingsovereenkomst");
      const isOpgeladenOvereenkomstNOKAndWijzigOvereenkomst =
        getMilestone("opgeladen_begeleidingsovereenkomst_niet_ok") &&
        getMilestone("wijzig_de_begeleidingsovereenkomst");
      const isOpgeladenOvereenkomstMostRecent =
        isOpgeladenOvereenkomstNOKAndWijzigOvereenkomst &&
        getMilestone("opgeladen_begeleidingsovereenkomst_niet_ok").timestamp >
          getMilestone("wijzig_de_begeleidingsovereenkomst").timestamp;
      if (isOpgeladenOvereenkomstNOK || isOpgeladenOvereenkomstMostRecent) {
        let errorMessage;
        if (
          getFieldFromDocument(
            "fbu_overeenkomst",
            "motivatie_als_niet_leesbaar",
          )
        ) {
          errorMessage = getFieldFromDocument("fbu_overeenkomst", [
            "motivatie_als_niet_leesbaar",
            "value",
          ]);
        } else if (
          getFieldFromDocument(
            "fbu_overeenkomst",
            "motivatie_als_niet_volledig",
          )
        ) {
          errorMessage = getFieldFromDocument("fbu_overeenkomst", [
            "motivatie_als_niet_volledig",
            "value",
          ]);
        } else {
          errorMessage = getFieldFromDocument("fbu_overeenkomst", [
            "motivatie_als_niet_ondertekend",
            "value",
          ]);
        }
        return [
          {
            profileTypes: [profileTypes.OCMW],
            id: dossier.id,
            title: "Opgeladen begeleidingsovereenkomst niet in orde",
            message: errorMessage,
            type: notificationTypes.WARNING,
          },
          genericNotificationCitizenOrCompany,
        ];
      }
      return [
        {
          profileTypes: [profileTypes.OCMW],
          id: dossier.id,
          title: "Begeleidingsovereenkomst ingevuld",
          message:
            "U vindt de begeleidingsovereenkomst hieronder terug onder documenten en downloads. U kunt deze begeleidingsovereenkomst downloaden, afdrukken en voorleggen aan de huurder(s), verhuurder(s) en de OCMW-ondertekenaar(s). Nadat alle partijen de begeleidingsovereenkomst hebben ondertekend dient u de papieren begeleidingsovereenkomst in te scannen en terug op te laden in het formulierenloket als pdf.",
          type: notificationTypes.SUCCESS,
        },
        genericNotificationCitizenOrCompany,
      ];
    },
    [milestoneKeys.ONDERTEKEND_OP_PAPIER]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Ondertekend op papier",
        message: `Uw aanvraag kreeg volgend dossiernummer toegewezen: ${dossier.label}. (Gelieve dit dossiernummer steeds te vermelden in verdere communicatie over dit dossier.)`,
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.DOSSIER_GESTOPT_FBU_DIENSTHOOFD]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: `Het dossier met dossiernummer ${dossier.label} werd stopgezet`,
        message: getFieldFromDocument("fbu_stopzetten_dossier", [
          "motivatie_stopzetten_dossier",
          "value",
        ]),
        type: notificationTypes.ERROR,
      },
      {
        profileTypes: [profileTypes.CITIZEN, profileTypes.COMPANY],
        id: dossier.id,
        title: "Het dossier met dossiernummer ${dossier.label} werd stopgezet",
        message: "",
        type: notificationTypes.SUCCESS,
      },
    ],
    [milestoneKeys.ADRESVALIDATIE_NEGATIEF]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Ondertekend op papier",
        message: `Uw aanvraag kreeg volgend dossiernummer toegewezen: ${dossier.label}. (Gelieve dit dossiernummer steeds te vermelden in verdere communicatie over dit dossier.)`,
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.DOSSIER_AFGEWEZEN]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: `Het dossier met dossiernummer ${dossier.label} werd stopgezet`,
        message: getFieldFromDocument("fbu_afwijzen_dossier", [
          "motivatie_afwijzen_dossier",
          "value",
        ]),
        type: notificationTypes.ERROR,
      },
      {
        profileTypes: [profileTypes.CITIZEN, profileTypes.COMPANY],
        id: dossier.id,
        title: "Het dossier met dossiernummer ${dossier.label} werd stopgezet",
        message: "",
        type: notificationTypes.SUCCESS,
      },
    ],
    [milestoneKeys.NEGATIEVE_ADRESVALIDATIE_OVERSCHREVEN]: () => [
      genericNotificationAllUsers,
    ],
    [milestoneKeys.BETALING_GESTART]: () => [genericNotificationAllUsers],
    [milestoneKeys.BETALING_GEIMPORTEERD]: () => [genericNotificationAllUsers],
    [milestoneKeys.EERSTE_UITBETALING_UITGEVOERD]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Eerste betaling uitgevoerd",
        message: "De eerste betaling voor het OCMW werd uitgevoerd.",
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.KLAAR_VOOR_ONDERTEKENING_DOOR_OCMW]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Aanvraag tegemoetkoming stabiele woonsituatie ingevuld",
        message:
          "Het formulier “aanvraag tegemoetkoming stabiele woonsituatie” is ingevuld. Dit formulier moet ingediend worden door de persoon binnen uw OCMW die bevoegd is om te ondertekenen (personen met de rol “FBU OCMW Ondertekenaar“ en/of “FBU OCMW AD“ ifv de instellingen op uw OCMW Profiel). Deze persoon meldt zich hiervoor aan op het formulierenloket. Na de ondertekening van de “aanvraag tegemoetkoming stabiele woonsituatie” vindt u de verklaring die de verhuurder dient af te leggen onder “documenten en downloads”.",
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.BEWIJS_STABIELE_WOONSITUATIE_GEWIJZIGD]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Aanvraag tegemoetkoming stabiele woonsituatie",
        message:
          "U kunt nu de aanvraag van tegemoetkoming stabiele woonsituatie wijzigen.",
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.BEWIJS_STABIELE_WOONSITUATIE_GEANNULEERD]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Aanvraag tegemoetkoming stabiele woonsituatie geannuleerd",
        message: "Indien gewenst, kunt u nu een nieuwe aanvraag invullen.",
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.STABIELE_WOONSITUATIE_BEWEZEN_DIGITAAL]: () => {
      const isBewijsGewijzigd = getMilestone(
        "bewijs_stabiele_woonsituatie_gewijzigd",
      );
      const isBewijsGeannuleerd = getMilestone(
        "bewijs_stabiele_woonsituatie_geannuleerd",
      );
      const isVerklaringNegatief =
        getMilestone("verklaring_negatief") &&
        !isBewijsGewijzigd &&
        !isBewijsGeannuleerd;
      const isVerklaringNegatiefAndActieBewijs =
        getMilestone("verklaring_negatief") &&
        (isBewijsGewijzigd || isBewijsGeannuleerd);
      const isVerklaringNegatiefMostRecent =
        isVerklaringNegatiefAndActieBewijs &&
        (isBewijsGewijzigd
          ? getMilestone("verklaring_negatief").timestamp >
            getMilestone("bewijs_stabiele_woonsituatie_gewijzigd").timestamp
          : isBewijsGeannuleerd
            ? getMilestone("verklaring_negatief").timestamp >
              getMilestone("bewijs_stabiele_woonsituatie_geannuleerd").timestamp
            : false);
      if (isVerklaringNegatief || isVerklaringNegatiefMostRecent) {
        return [
          {
            profileTypes: [profileTypes.OCMW],
            id: dossier.id,
            title: "Verklaring van de verhuurder is negatief",
            message: getFieldFromDocument("fbu_verklaring_verhuurder", [
              "motivatie_verklaring_negatief",
              "value",
            ]),
            type: notificationTypes.WARNING,
          },
          genericNotificationCitizenOrCompany,
        ];
      }
      return [
        {
          profileTypes: [profileTypes.OCMW],
          id: dossier.id,
          title: "Uw aanvraag werd goed ontvangen",
          message:
            "Uw aanvraag werd goed ontvangen. De verhuurder dient de verklaring van de verhuurder digitaal in te vullen en te ondertekenen. De verhuurder kan de verklaring digitaal ondertekenen door in te loggen op het formulierenloket.",
          type: notificationTypes.SUCCESS,
        },
        genericNotificationCitizenOrCompany,
      ];
    },
    [milestoneKeys.VERKLARING_DIGITAAL_ONDERTEKEND]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Uw aanvraag wordt behandeld",
        message: `Het dossier met dossiernummer ${dossier.label} wordt momenteel behandeld.`,
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.STABIELE_WOONSITUATIE_BEWEZEN_PAPIER]: () => {
      const isBewijsGewijzigd = getMilestone(
        "bewijs_stabiele_woonsituatie_gewijzigd",
      );
      const isBewijsGeannuleerd = getMilestone(
        "bewijs_stabiele_woonsituatie_geannuleerd",
      );
      const isVerklaringNegatief =
        getMilestone("verklaring_negatief") &&
        !isBewijsGewijzigd &&
        !isBewijsGeannuleerd;
      const isVerklaringNegatiefAndActieBewijs =
        getMilestone("verklaring_negatief") &&
        (isBewijsGewijzigd || isBewijsGeannuleerd);
      const isVerklaringNegatiefMostRecent =
        isVerklaringNegatiefAndActieBewijs &&
        (isBewijsGewijzigd
          ? getMilestone("verklaring_negatief").timestamp >
            getMilestone("bewijs_stabiele_woonsituatie_gewijzigd").timestamp
          : isBewijsGeannuleerd
            ? getMilestone("verklaring_negatief").timestamp >
              getMilestone("bewijs_stabiele_woonsituatie_geannuleerd").timestamp
            : false);
      if (isVerklaringNegatief || isVerklaringNegatiefMostRecent) {
        return [
          {
            profileTypes: [profileTypes.OCMW],
            id: dossier.id,
            title: "Verklaring van de verhuurder is negatief",
            message: getFieldFromDocument("fbu_verklaring_verhuurder", [
              "motivatie_verklaring_negatief",
              "value",
            ]),
            type: notificationTypes.WARNING,
          },
          genericNotificationCitizenOrCompany,
        ];
      }
      return [
        {
          profileTypes: [profileTypes.OCMW],
          id: dossier.id,
          title: "Uw aanvraag werd goed ontvangen",
          message:
            "Uw aanvraag werd goed ontvangen. U kunt de verklaring van de verhuurder hieronder downloaden bij Documenten en Downloads. Gelieve deze aan de verhuurder(s) te bezorgen, zodat de verhuurder(s) deze verklaring kunnen invullen en ondertekenen. Daarna kan u de ingevulde en ondertekende verklaring van de verhuurder hier opladen.",
          type: notificationTypes.SUCCESS,
        },
        genericNotificationCitizenOrCompany,
      ];
    },
    [milestoneKeys.VERKLARING_ONDERTEKEND_OP_PAPIER]: () => [
      {
        profileTypes: [profileTypes.OCMW],
        id: dossier.id,
        title: "Uw aanvraag wordt behandeld",
        message: `Het dossier met dossiernummer ${dossier.label} wordt momenteel behandeld.`,
        type: notificationTypes.SUCCESS,
      },
      genericNotificationCitizenOrCompany,
    ],
    [milestoneKeys.OPGELADEN_VERKLARING_NIET_OK]: () => {
      let errorMessage;
      if (
        getFieldFromDocument(
          "fbu_verklaring_verhuurder",
          "motivatie_als_niet_leesbaar",
        )
      ) {
        errorMessage = getFieldFromDocument("fbu_verklaring_verhuurder", [
          "motivatie_als_niet_leesbaar",
          "value",
        ]);
      } else {
        errorMessage = getFieldFromDocument("fbu_verklaring_verhuurder", [
          "motivatie_als_niet_ondertekend",
          "value",
        ]);
      }
      return [
        {
          profileTypes: [profileTypes.OCMW],
          id: dossier.id,
          title: "Opgeladen verklaring van de verhuurder niet in orde",
          message: errorMessage,
          type: notificationTypes.WARNING,
        },
        genericNotificationCitizenOrCompany,
      ];
    },
    [milestoneKeys.AANVRAAG_AFGEWERKT]: () => [
      {
        profileTypes: [
          profileTypes.OCMW,
          profileTypes.CITIZEN,
          profileTypes.COMPANY,
        ],
        id: dossier.id,
        title: "Dossier afgewerkt",
        message: `Het dossier met dossiernummer ${dossier.label} wordt afgesloten.`,
        type: notificationTypes.SUCCESS,
      },
    ],
  };
  const notificationForMilestone = notifications[milestoneKey]
    ? notifications[milestoneKey]()
    : [];
  const notificationForUser = notificationForMilestone
    .filter((notification) => notification.profileTypes.includes(profileType))
    .map((notification) => omit(notification, ["profileTypes"]));
  return notificationForUser;
}
