import store from "../store";
import {
  fetchInformatieFromHoofddossier,
  fetchSubdossiersOfHoofddossier,
} from "../store/actions";

export function getSubdossiersFromHoofddossier(
  hoofddossierId,
  formulier,
  page = null,
  size = null,
) {
  return store
    .dispatch(
      fetchSubdossiersOfHoofddossier(hoofddossierId, formulier, page, size),
    )
    .then(({ api }) => api.response.data);
}

export function retrieveInformatieFromHoofddossier(
  hoofddossierId,
  dossierType,
) {
  return store
    .dispatch(fetchInformatieFromHoofddossier(hoofddossierId, dossierType))
    .then(({ api }) => api.response.data);
}
