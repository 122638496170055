import { filter, get, map, valuesIn, find } from "lodash";

export function dossierDefinitionsToCallToAction(
  dossierDefinitions,
  dossierDefinitionKeysWhitelist,
) {
  const dossierDefinitionsAsArray = valuesIn(dossierDefinitions);
  const whitelistedDossierDefinitions = filter(
    dossierDefinitionsAsArray,
    (dossierDefinition) => {
      return dossierDefinitionKeysWhitelist.indexOf(dossierDefinition.key) > -1;
    },
  );
  const dossierCreateButtons = map(
    whitelistedDossierDefinitions,
    (dossierDefinition) => {
      const creationModes = get(dossierDefinition, ["data", "creationModes"]);
      return {
        dosdefKey: get(dossierDefinition, ["key"]),
        label: get(dossierDefinition, ["label"]),
        description: get(dossierDefinition, ["data", "description"]),
        startButtonLabel: get(dossierDefinition, ["data", "startButtonLabel"]),
        isCreationModeEloket: Boolean(find(creationModes, { key: "eLoket" })),
      };
    },
  );
  return filter(dossierCreateButtons, "isCreationModeEloket");
}
