import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";

import {
  dossierPageWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";

import {
  selectCurrentMilestoneWithDossierId,
  selectProfileTypeFromFlProfile,
} from "../store/selectors";
import { fetchCurrentDossierMilestone } from "@skryv/core-react/src/core/store/actions/dossiers";
import { fetchFlProfile } from "../store/actions";

import FlFBUDossier from "./flFBUDossier";
import FlVGWDossier from "./flVGWDossier";
import FlHPDossier from "./flHPDossier";
import FlHPWTDossier from "./flHPWTDossier";
import FlOCMWDossier from "./flOCMWDossier";
import FlHSDossier from "./flHSDossier";
import FlOVREDossier from "./flOVREDossier";
import FlOVREGasDossier from "./flOVREGasDossier";
import FlNWDossier from "./flNWDossier";
import FlHLDossier from "./flHlDossier";
import FlJOSTDossier from "./flJOSTDossier";
import FlVgwVhrDossier from "./flVgwVhrDossier";
import FlDTLDossier from "./flDTLDossier";
import FlVlokDossier from "./flVlokDossier";
import FlVlokSubDossier from "./flVlokSubDossier";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";
import dosdefKeys from "../constants/dossierDefinitionKeys";

import "./flDossier.scss";
import FlGVDossier from "./flGVDossier";

export class flDossier extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;

  componentDidMount() {
    this.props.fetchCurrentDossierMilestone(this.props.dossier.id);
    this.props.fetchFlProfile();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dossierDetails && prevProps.dossierDetails) {
      if (
        this.props.dossierDetails.dossier.latestActivity !==
        prevProps.dossierDetails.dossier.latestActivity
      ) {
        this.props.fetchCurrentDossierMilestone(this.props.dossier.id);
      }
    }
  }

  render() {
    const dossierDefinitionName = get(
      this.props.dossierDetails,
      ["dossier", "dossierDefinition", "key"],
      "",
    );
    if (dossierDefinitionName === dosdefKeys.DTL) {
      return <FlDTLDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.FBU) {
      return <FlFBUDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.OCMW) {
      return <FlOCMWDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.VGW) {
      return <FlVGWDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.HP) {
      return <FlHPDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.HPWT) {
      return <FlHPWTDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.HS) {
      return <FlHSDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.OVRE) {
      return <FlOVREDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.OVRE_GAS) {
      return <FlOVREGasDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.NW) {
      return <FlNWDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.JOST) {
      return <FlJOSTDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.HL) {
      return <FlHLDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.VGWVHR) {
      return <FlVgwVhrDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.GV) {
      return <FlGVDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.OWLD_GV) {
      return <FlGVDossier {...this.props} />;
    }
    if (dossierDefinitionName === dosdefKeys.ACA) {
      return (
        <FlVlokDossier
          {...this.props}
          dossierType="aanvraagConformiteitsattest"
        />
      );
    }
    if (dossierDefinitionName === dosdefKeys.AKL) {
      return <FlVlokDossier {...this.props} dossierType="aanvraagKotlabel" />;
    }
    if (dossierDefinitionName === dosdefKeys.KL) {
      return <FlVlokSubDossier {...this.props} dossierType="kotlabel" />;
    }
    if (dossierDefinitionName === dosdefKeys.CA) {
      return (
        <FlVlokSubDossier {...this.props} dossierType="conformiteitsattest" />
      );
    }

    return <CoreDossierPage {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  const isOCMW = selectProfileTypeFromFlProfile(state) === "ocmw";
  return {
    currentMilestone: selectCurrentMilestoneWithDossierId(
      state,
      props.dossier.id,
    ),
    userType: selectProfileTypeFromFlProfile(state),
    isOCMW,
  };
};

const mapDispatchToProps = {
  fetchCurrentDossierMilestone,
  fetchFlProfile,
};

export default dossierPageWrapper(
  connect(mapStateToProps, mapDispatchToProps)(flDossier),
);
