import PropTypes from "prop-types";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import React, { useContext } from "react";
import contextType from "@skryv/core-react/src/services/contextTypes";

export function DossierSectionLayout({ title, type, icon, children }) {
  const context = useContext(contextType);
  return (
    <div className={`dossier-${type} vl-col--1-1`}>
      <section className="vl-infoblock">
        <Title
          level={2}
          title={context.gettext(title)}
          iconClass={`vl-vi-${icon}`}
        />
        {children}
      </section>
    </div>
  );
}

DossierSectionLayout.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
