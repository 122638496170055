import React, { useContext } from "react";
import PropTypes from "prop-types";
import contextType from "@skryv/core-react/src/services/contextTypes";

export const Alert = ({
  title,
  message,
  type = "info",
  icon = "info-circle",
  kotlabelLink = undefined,
  naked = false,
}) => {
  const context = useContext(contextType);

  return (
    <div
      className={`vl-alert vl-alert--${type}${naked ? " vl-alert--naked" : ""}`}
      role="alert"
    >
      <div className="vl-alert__icon">
        <i className={`vl-vi vl-vi-${icon}`} aria-hidden="true"></i>
      </div>
      <div className="vl-alert__content">
        <p className="vl-alert__title">{context.gettext(title)}</p>
        <div className="vl-alert__message">
          <p className={naked ? "vl-u-mark--success" : ""}>
            {context.gettext(message)}
          </p>
          {kotlabelLink && (
            <a href={kotlabelLink} target="_blank" rel="noreferrer">
              {kotlabelLink}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "info", "warning", "error", "small"]),
  icon: PropTypes.oneOf(["check-circle", "info-circle", "warning", "cross"]),
  kotlabelLink: PropTypes.string,
  naked: PropTypes.bool,
};
