import {
  SkryvFrontOfficeApp,
  form,
  navigation,
  translation,
} from "@skryv/core-react";
import "@skryv/core-react-vo";
import store from "../src/customizations/store";

import translations from "../translations";
import "./theme/overrides.scss";

import { registerDefaultRouting } from "@skryv/core-react-vo/src/config/routing";

import { registerDefaultTranslations } from "@skryv/core-react-vo/src/config/translations";

import flDashboard from "./customizations/components/flDashboard";
import flDossier from "./customizations/components/flDossier";
import flDossierCreator from "./customizations/components/flDossierCreator";
import flHeader from "./customizations/components/flHeader";
import flFooter from "./customizations/components/flFooter";

import { layoutNames } from "@skryv/core-react/src/config/layouts";
import { pageNames } from "@skryv/core-react/src/config/pages";

import { fetchGemeenteParameters } from "./customizations/store/actions";
import {
  AddressWithGemeenteParametersForConformiteitsattest,
  AddressWithGemeenteParametersForKotLabel,
} from "./customizations/components/form/AddressWithGemeenteParameters";

function retrieveGemeenteParameters(postcode, dossierType) {
  return store
    .dispatch(fetchGemeenteParameters(postcode, dossierType))
    .then(({ api }) => api.response.data);
}

form
  .setFormConfig({
    showSectionWizard: true,
    showDocumentTitle: false,
    attachments: {
      acceptedMimeTypes: ["pdf"],
    },
    customRequest: {
      retrieveGemeenteParameters,
    },
  })
  .addCustomComponent(
    "skrAddressWithGemeenteParametersForKotLabel",
    AddressWithGemeenteParametersForKotLabel,
  )
  .addCustomComponent(
    "skrAddressWithGemeenteParametersForConformiteitsattest",
    AddressWithGemeenteParametersForConformiteitsattest,
  );

class FlApp extends SkryvFrontOfficeApp {
  constructor() {
    super(store);
  }
}

const CREATE_VGW_DOSSIER_PAGE = "vgw";

const customizedNavigation = registerDefaultRouting(navigation)
  .layout(layoutNames.FRONTOFFICE, {
    extend: true,
    views: {
      header: flHeader,
      footer: flFooter,
    },
  })
  .page(CREATE_VGW_DOSSIER_PAGE, {
    layout: layoutNames.FRONTOFFICE,
    name: CREATE_VGW_DOSSIER_PAGE,
    url: "/vgw",
    views: {
      content: flDossierCreator,
    },
    needsAuthentication: true,
  })
  .page(pageNames.DASHBOARD, {
    extend: true,
    views: {
      content: flDashboard,
    },
  })
  .page(pageNames.DOSSIER, {
    extend: true,
    views: {
      content: flDossier,
    },
  });

const customizedTranslation = registerDefaultTranslations(translation)
  .locale("nl_BE") // set the language for the core components
  .add("nl_BE", translations.nl_BE); // make sure the custom client translations are also available

FlApp.createSkryvApp()
  .customize(form)
  .customize(customizedNavigation)
  .customize(customizedTranslation)
  .initialize(document.getElementById("root"));
