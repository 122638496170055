/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { keyBy } from "lodash";
import { fetchAllDossierDefinitions } from "@skryv/core-react/src/core/store/actions/dosdefs";
import { changeTaskAssignee } from "@skryv/core-react/src/core/store/actions/tasks";
import {
  createDossier,
  fetchDossierDetails,
} from "@skryv/core-react/src/core/store/actions/dossiers";
import { selectAllDossierDefinitions } from "@skryv/core-react/src/core/store/selectors/dosdefs";
import { selectCurrentUser } from "@skryv/core-react/src/core/store/selectors/users";
import {
  createNewDossier,
  getCreationModeKeyForDosdef,
} from "@skryv/core-react/src/services/dossierCreation";

import "./flDossierCreator.scss";

const VGW_DOSDEF_KEY = "vgw";

class flDossierCreator extends React.Component {
  componentDidMount() {
    this.props
      .fetchAllDossierDefinitions()
      .then(() => this.props.dosdefs[VGW_DOSDEF_KEY])
      .then((dosdef) => {
        createNewDossier(
          dosdef,
          this.props.createDossier,
          this.props.fetchDossierDetails,
          getCreationModeKeyForDosdef,
          true,
          this.props.changeTaskAssignee,
          this.props.currentUser,
        );
      });
  }

  render() {
    return (
      <div className="vl-col--1-1">
        <div className="vl-region">
          <div className="vl-u-align-center">
            <div className="vl-loader" role="alert" aria-busy="true"></div>
            <h1>Uw dossier wordt aangemaakt.</h1>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dosdefs: keyBy(selectAllDossierDefinitions(state), "key"),
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = {
  changeTaskAssignee,
  fetchAllDossierDefinitions,
  fetchDossierDetails,
  createDossier,
};

export default connect(mapStateToProps, mapDispatchToProps)(flDossierCreator);
