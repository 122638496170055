import React from "react";
import { get } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";

import contactInformation from "../constants/contactInformation";

import milestoneDefKeys from "../constants/milestoneDefinitionKeys"; // import milestone definition keys

export function flDossierWrapper() {
  class flDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation,
        dossierInformation: this.props.dossierInformation,
        notifications: this.props.notifications,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          notifications: this.getNotifications(),
        });
      }
    }

    getNotifications() {
      const milestone_ontvangstbevestiging = this.props.getMilestone(
        milestoneDefKeys.HP_ONTVANGEN,
      );

      let notifications = [];

      if (milestone_ontvangstbevestiging) {
        notifications = [
          {
            id: this.props.dossier.id,
            message: this.context.gettext(
              "Uw bestanden werden goed ontvangen.",
            ),
            title: this.context.gettext("Bestanden goed ontvangen"),
            type: notificationTypes.SUCCESS,
          },
        ];
      }

      return notifications;
    }

    getDossierInformation() {
      const dossierInformation = [];

      if (this.props.dossierDetails) {
        const ocmwIban = this.props.getFieldFromDocument(
          "fbu_ocmw_gegevens",
          "rekeningnummer",
        );
        dossierInformation.push({
          value:
            ocmwIban !== undefined
              ? this.context.gettext("BE" + this.context.gettext(ocmwIban))
              : "",
          label: this.context.gettext("Rekeningnummer"),
        });

        const ocmwEmail = this.props.getFieldFromDocument(
          "fbu_ocmw_gegevens",
          "email",
        );
        dossierInformation.push({
          value: ocmwEmail,
          label: this.context.gettext("E-mailadres"),
        });

        const ocmwSign = this.props.getFieldFromDocument("fbu_ocmw_gegevens", [
          "ondertekening",
          "selectedOptionLabel",
          "nl",
        ]);
        dossierInformation.push({
          value: ocmwSign,
          label: this.context.gettext("Ondertekening"),
        });
      }

      if (this.props.dossier) {
        dossierInformation.push({
          value: moment(
            get(this.props.dossierDetails, ["dossier", "latestActivity"]),
          ).format("DD-MM-YYYY"),
          label: this.context.gettext("Last update"),
        });
      }

      return dossierInformation;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        notifications: this.state.notifications,
        dossierInformation: this.state.dossierInformation,
      };

      return <CoreDossierPage {...componentsToPass} />;
    }
  }

  return flDossier;
}

export default coreDossierWrapper(flDossierWrapper());
