import React, { useContext } from "react";
import { criteria } from "./BeoordelingsCriteria";
import PropTypes from "prop-types";
import { reject, isNil } from "lodash";
import contextType from "@skryv/core-react/src/services/contextTypes";

export const CriteriaTable = (props) => {
  const context = useContext(contextType);
  const filteredCriteria = reject(props.criteria, isNil);

  const mapBeoordelingToThumbsUpOrThumbsDown = (beoordeling) => {
    switch (beoordeling) {
      case "gunstig":
        return getBeoordeling(
          context.gettext("Gunstig"),
          "vl-vi-vote-thumbs-up",
          "vl-u-text--success",
        );
      case "ongunstig":
        return getBeoordeling(
          context.gettext("Ongunstig"),
          "vl-vi-vote-thumbs-down",
          "vl-u-text--error",
        );
      case "waarschuwing":
        return getBeoordeling(
          context.gettext("Waarschuwing"),
          "vl-vi-alert-triangle",
          "vl-u-text--warning",
        );
      default:
        return beoordeling;
    }
  };

  return (
    <div className="skryv-table">
      <table className="vl-data-table vl-data-table--hover vl-data-table--collapsed-s">
        <thead>
          <tr>
            {props.tableColumns.map((column) => (
              <th
                key={column.key}
                data-title={column.text}
                style={{
                  width: "50%",
                  paddingLeft: column.key === "beoordeling" ? "1.5rem" : 0,
                }}
              >
                {column.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredCriteria.map((record) => (
            <tr tabIndex="0" key={`${record.criterium}-${record.beoordeling}`}>
              {props.tableColumns.map((column) => (
                <td
                  data-title={column.text}
                  key={`${column.key}-${column.text}`}
                  style={{
                    width: "50%",
                    paddingLeft: column.key === "beoordeling" ? "1.5rem" : 0,
                  }}
                >
                  {mapBeoordelingToThumbsUpOrThumbsDown(record[column.key])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

CriteriaTable.propTypes = {
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  criteria: criteria,
};

const getBeoordeling = (text, icon, color) => {
  return (
    <div className={`vl-u-display-flex ${color}`}>
      <span className={`vl-icon vl-vi ${icon}`} aria-hidden="true"></span>
      <p className="vl-u-spacer-left--small">{text}</p>
    </div>
  );
};
