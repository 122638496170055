import React from "react";
import PropTypes from "prop-types";

import "./MaxLengthTableItem.scss";

export default class MaxLengthTableItem extends React.Component {
  static propTypes = {
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <div
        className="container"
        onMouseEnter={() => this.setState({ show: true })}
        onMouseLeave={() => this.setState({ show: false })}
      >
        <p>
          {this.props.value && this.props.value.length > 25
            ? this.props.value.trim().substring(0, 26) + "..."
            : this.props.value}
        </p>
        {this.props.value &&
          this.props.value.length > 25 &&
          this.state.show && <p className="hoverItem">{this.props.value}</p>}
      </div>
    );
  }
}
