import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { isInIframe } from "@skryv/core-react/src/core/util/iframe-utilities";

import { postscribePromise } from "../services/postscribe";

import { fetchFlConfiguration } from "../store/actions";
import {
  hasLoadedBurgerprofiel,
  selectBurgerprofielFooter,
} from "../store/selectors";

const reduxProps = {
  hasLoadedBurgerprofiel: PropTypes.bool,
  burgerprofielFooterEmbedCode: PropTypes.string,
  fetchFlConfiguration: PropTypes.func,
};

class flFooter extends React.Component {
  static propTypes = reduxProps;
  constructor(props) {
    super(props);
    props.fetchFlConfiguration();

    this.state = {
      hasInitializedFooterScript: false,
      footerSelector: "#footer-script",
    };
  }

  componentDidUpdate() {
    this.loadMijnBurgerProfielFooter();
  }

  loadMijnBurgerProfielFooter() {
    // if we are running in the MBP iframe, don't show the header
    if (isInIframe) return;

    // the footer script has already loaded or we are still loading the needed information, don't do anything
    if (
      this.state.hasInitializedFooterScript ||
      !this.props.hasLoadedBurgerprofiel
    )
      return;

    // we have all information to use the Mijn Burgerprofiel footer
    if (this.props.burgerprofielFooterEmbedCode) {
      const burgerprofielPolyfillSrc =
        "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js";

      return postscribePromise(
        this.state.footerSelector,
        burgerprofielPolyfillSrc,
      )
        .then(() =>
          postscribePromise(
            this.state.footerSelector,
            this.props.burgerprofielFooterEmbedCode,
          ),
        )
        .then(() => this.setState({ hasInitializedFooterScript: true }));
    }
    // otherwise we display the VO fallback footer
    else {
      const voFooterEmbedCode =
        "//widgets.vlaanderen.be/widget/live/e898970e8ee94447882f7fc5eee45ed8";
      postscribePromise(this.state.footerSelector, voFooterEmbedCode).then(() =>
        this.setState({ hasInitializedFooterScript: true }),
      );
    }
  }

  render() {
    if (isInIframe) return null;

    return <div id="footer-script"></div>;
  }
}

const mapStateToProps = (state) => ({
  hasLoadedBurgerprofiel: hasLoadedBurgerprofiel(state),
  burgerprofielFooterEmbedCode: selectBurgerprofielFooter(state),
});

const mapDispatchToProps = {
  fetchFlConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(flFooter);
