import { get, isEmpty } from "lodash";

export function selectRrnFromFlProfile(state) {
  return get(state, ["profile", "rrn"]);
}

export function selectProfileTypeFromFlProfile(state) {
  return get(state, ["profile", "type"]);
}

export function isLBAanvrager(state) {
  return get(state, ["profile", "isLbAanvrager"]);
}

export function selectCurrentMilestoneWithDossierId(state, dossierId) {
  return get(state, ["currentMilestone", dossierId]);
}

export function selectOcmwDossier(state) {
  return get(state, ["ocmwDossier"]);
}

export function selectBurgerprofielHeader(state) {
  return get(state, [
    "flConfiguration",
    "configuration",
    "burgerprofiel",
    "headerEmbedCode",
  ]);
}

export function selectBurgerprofielFooter(state) {
  return get(state, [
    "flConfiguration",
    "configuration",
    "burgerprofiel",
    "footerEmbedCode",
  ]);
}

export function hasLoadedBurgerprofiel(state) {
  return (
    !get(state, ["flConfiguration", "loading"]) &&
    !isEmpty(get(state, ["flConfiguration", "configuration"]))
  );
}
