import React from "react";
import { get } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";

import { DossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";

import contactInformation from "../constants/contactInformation";

import milestoneDefKeys from "../constants/milestoneDefinitionKeys";

export function flDossierWrapper() {
  class flDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation: {
          ...contactInformation,
          email: "vgw@vlaanderen.be",
          title: "VGW",
          address: undefined,
          lat: undefined,
          long: undefined,
          mapUrl: undefined,
          phone: undefined,
        },
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.props.notifications,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      //       this.getDownloads = this.getDownloads.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          //           downloads: this.getDownloads(),
          notifications: this.getNotifications(),
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if (this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier, "label"),
          label: this.context.gettext("Dossiernummer"),
        });
        dossierInformation.push({
          value: moment(
            get(this.props.dossierDetails, ["dossier", "latestActivity"]),
          ).format("DD-MM-YYYY"),
          label: this.context.gettext("Last update"),
        });
      }

      return dossierInformation;
    }

    getNotifications() {
      const milestone_indiening = this.props.getMilestone(
        milestoneDefKeys.VGWVHR_INGEDIEND,
      );
      const milestone_beslist = this.props.getMilestone(
        milestoneDefKeys.VGWVHR_BESLIST,
      );

      let notifications = [];

      if (milestone_beslist) {
        notifications = [
          {
            id: this.props.dossier.id,
            message: this.context.gettext(
              'Raadpleeg uw beslissingsbrief onder "Documenten en Downloads".',
            ),
            title: this.context.gettext("Uw dossier werd beslist"),
            type: notificationTypes.SUCCESS,
          },
        ];
      } else if (milestone_indiening) {
        notifications = [
          {
            id: this.props.dossier.id,
            message: this.context.gettext("Uw formulier werd ingediend."),
            title: this.context.gettext("Formulier ingediend"),
            type: notificationTypes.SUCCESS,
          },
        ];
      }

      return notifications;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.state.notifications,
        shouldShowDownloads: true,
      };

      return <DossierPage {...componentsToPass} />;
    }
  }

  return flDossier;
}
//
// class OVREDossierPage extends React.Component {
//   static propTypes = { ...externalProps, ...internalProps };
//   static defaultProps = defaultProps;
//   static contextType = contextType;
//
//   renderTitle() {
//     return (
//       <div className="dossier-title vl-col--1-1">
//         <Title level={ 1 } title={ this.props.dossier.label } />
//       </div>
//     );
//   }
//
//   renderDossierProgress() {
//     const steps = this.props.getDossierProgressSteps();
//     return steps && <DossierProgress steps={ steps } />;
//   }
//
//   renderNotification() {
//     return (this.props.notifications && this.props.notifications.length > 0 &&
//       <div className="dossier-notifications vl-col--1-1">
//         { map(this.props.notifications, (notification, key) =>
//           <Notification notification={ notification } class="dossier-notification" key={ key } />
//         ) }
//       </div>
//     );
//   }
//
//   renderActiveTasks() {
//     if (this.props.loadingDossierDetails) {
//       return <p>{ this.context.gettext('Loading new dossier information...') }</p>;
//     }
//
//     const activeTasks = this.props.getMyActiveTasksInDossier();
//     return (activeTasks && activeTasks.length > 0 &&
//       <div className="dossier-active-tasks vl-col--1-1">
//         <ActiveTasks activeTasks={ activeTasks } execute={ this.props.executeTask } />
//       </div>
//     );
//   }
//
//   renderEnabledTasks() {
//     const { enabledTasks } = this.props;
//
//     if (!enabledTasks || isEmpty(enabledTasks)) return null;
//
//     function mapActivityToTask(activity) {
//       return {
//         id: activity.id,
//         name: activity.activityName,
//         description: activity.activityDescription
//       };
//     }
//
//     const mappedEnabledTasks = map(enabledTasks, mapActivityToTask);
//
//     return (
//       <div className="dossier-title vl-col--1-1">
//         <Title level={ 2 } title="Dossier Acties" iconClass="vl-vi-cursor-hand"/>
//         <ActiveTasks activeTasks={ mappedEnabledTasks } execute={ this.props.handleEnabledTaskSelect }/>
//       </div>
//     );
//   }
//
//   renderDossierInformation() {
//     return (this.props.dossierInformation && this.props.dossierInformation.length > 0 &&
//       <div className="dossier-information vl-col--1-1">
//         <section className="vl-infoblock">
//           <Title level={ 2 } title={ this.context.gettext('Dossier details') } iconClass="vl-vi-news" />
//           <InfoBlock infoBlockData={ this.props.dossierInformation } columnsNumber={ this.props.dossierInformationNbColumns } />
//         </section>
//       </div>
//     );
//   }
//
//   renderDownloads() {
//     return (this.props.downloads && this.props.downloads.length > 0 &&
//       <div className="dossier-downloads vl-col--1-1">
//         <section className="vl-infoblock">
//           <Title level={ 2 } title={ this.context.gettext('Documents and Downloads') } iconClass="vl-vi-file-download" />
//           <FileDisplay downloads={ this.props.downloads } />
//         </section>
//       </div>
//     );
//   }
//
//   renderContactInformation() {
//     return (this.props.contactInformation &&
//       <div className="dossier-contact vl-col--1-1">
//         <section className="vl-infoblock">
//           <Title level={ 2 } title={ this.context.gettext('Contact') } iconClass="vl-vi-chat" />
//           <Contact contactInformation={ this.props.contactInformation } />
//         </section>
//       </div>
//     );
//   }
//
//   renderActivateTaskWarningModal() {
//     return (
//       <Modal
//         open={ this.props.shouldShowActivateTaskWarningModal }
//         text={ this.context.gettext('Ben je zeker dat je deze actie wilt uitvoeren?') }
//         title={ `${this.context.gettext('Voer')} ${
//           (this.props.modalTask.activityName || '').toLowerCase()
//           } ${this.context.gettext('uit')}` }
//         yesText={ this.context.gettext('Yes') }
//         noText={ this.context.gettext('No') }
//         yes={ () => this.props.toEnabledTask(this.props.modalTask.id) }
//         no={ () => this.props.cancelActivationOfEnabledTask() }
//         modalIdentifier={ this.props.modalTask.id || 'default' }
//       />
//     );
//   }
//
//   render() {
//     return (
//       <PageContent contentName="dossier">
//         { this.renderDossierProgress() }
//         <div className="dossier-page-header">
//           <div className="dossier-page-header-title">
//             { this.props.shouldShowTitle && this.renderTitle() }
//           </div>
//         </div>
//         { this.renderActivateTaskWarningModal() }
//         { this.props.shouldShowNotifications && this.renderNotification() }
//         { this.props.shouldShowActiveTasks && this.renderActiveTasks() }
//         { this.props.shouldShowDossierInformation && this.renderDossierInformation() }
//         { this.props.shouldShowDownloads && this.renderDownloads() }
//         { this.renderEnabledTasks() }
//         { this.props.shouldShowContactInformation && this.renderContactInformation() }
//       </PageContent>
//     );
//   }
// }

export default flDossierWrapper();
// coreDossierWrapper(flDossierWrapper());
