import React from "react";
import { get, map, isEmpty } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";

import Notification from "@skryv/core-react-vo/src/components/base/Notification/Notification";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import InfoBlock from "@skryv/core-react-vo/src/components/base/InfoBlock/InfoBlock";
import FileDisplay from "@skryv/core-react-vo/src/components/base/FileDisplay/FileDisplay";
import Contact from "@skryv/core-react-vo/src/components/base/Contact/Contact";
import Modal from "@skryv/core-react-vo/src/components/base/Modal/Modal";
import ActiveTasks from "@skryv/core-react-vo/src/components/dossier/ActiveTasks/ActiveTasks";
import Table from "@skryv/core-react-vo/src/components/base/Table/Table";
import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";

import { getNotifications } from "../helpers/notificationsHelper";

import contactInformation from "../constants/contactInformation";
import roleKeys from "../constants/roleKeys";

export function flDossierWrapper() {
  class flDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);
      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state and will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation,
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];

      if (this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier, "label"),
          label: this.context.gettext("Dossier label"),
        });
        if (this.props.isOCMW) {
          dossierInformation.push({
            value: get(this.props.currentMilestone, ["label"]),
            label: this.context.gettext("Status"),
          });
        } else {
          dossierInformation.push({});
        }
      }

      if (this.props.dossierDetails) {
        const tenants = this.props.getFieldFromDocument("fbu_overeenkomst", [
          "huurders",
          "elements",
        ]);
        const tenantsNames = tenants
          .map((tenant) => `${tenant.voornaam || ""} ${tenant.naam || ""}`)
          .join(", ");
        dossierInformation.push({
          value: tenantsNames,
          label: this.context.gettext("Huurder"),
        });

        const ownerType = this.props.getFieldFromDocument("fbu_overeenkomst", [
          "type_verhuurder",
          "selectedOption",
        ]);
        const ownersNatuurlijkPersoon = this.props.getFieldFromDocument(
          "fbu_overeenkomst",
          ["verhuurders", "elements"],
        );
        const ownersRechtsPersoon = this.props.getFieldFromDocument(
          "fbu_overeenkomst",
          ["verhuurder_is_rechtspersoon", "naam_onderneming"],
        );
        switch (ownerType) {
          case "rechtspersoon":
            dossierInformation.push({
              value: ownersRechtsPersoon,
              label: this.context.gettext("Verhuurder"),
            });
            break;
          case "natuurlijk_persoon":
            const ownersNames = ownersNatuurlijkPersoon
              .map((owner) => `${owner.voornaam || ""} ${owner.naam || ""}`)
              .join(", ");
            dossierInformation.push({
              value: ownersNames,
              label: this.context.gettext("Verhuurder"),
            });
            break;
          default:
            dossierInformation.push({
              value: "",
              label: this.context.gettext("Verhuurder"),
            });
        }
      }

      if (this.props.dossier) {
        dossierInformation.push({
          value: moment(
            get(this.props.dossierDetails, ["dossier", "latestActivity"]),
          ).format("DD-MM-YYYY"),
          label: this.context.gettext("Last update"),
        });
      }

      return dossierInformation;
    }

    getDownloads() {
      const downloads = [];
      if (
        this.props.getMilestone("begeleidingsovereenkomst_ingevuld_papier") ||
        this.props.getMilestone("begeleidingsovereenkomst_ingevuld_digitaal")
      ) {
        downloads.push(
          this.props.mapDocumentToPdfDownloadInfo(
            this.props.getDocument("fbu_overeenkomst"),
            "Begeleidingsovereenkomst",
            "fbu_papieren_ondertekening",
          ),
        );
      }
      if (this.props.getMilestone("stabiele_woonsituatie_bewezen_papier")) {
        downloads.push(
          this.props.mapDocumentToPdfDownloadInfo(
            this.props.getDocument("fbu_verklaring_verhuurder"),
            "Verklaring van de verhuurder",
            "fbu_papieren_verklaring",
          ),
        );
      }
      if (this.props.getMilestone("verklaring_digitaal_ondertekend")) {
        downloads.push(
          this.props.mapDocumentToPdfDownloadInfo(
            this.props.getDocument("fbu_verklaring_verhuurder"),
            "Verklaring van de verhuurder",
            "fbu_papieren_verklaring",
          ),
        );
      }
      return downloads;
    }

    render() {
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
        notifications: getNotifications(
          get(this.props.currentMilestone, ["key"]),
          this.props.userType,
          this.props.dossier,
          this.props.getFieldFromDocument,
          this.props.getMilestone,
        ),
      };

      return <FBUDossierPage {...componentsToPass} />;
    }
  }

  return flDossier;
}

class FBUDossierPage extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  renderTitle() {
    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={1} title={this.props.dossier.label} />
      </div>
    );
  }

  renderNotification() {
    return (
      this.props.notifications &&
      this.props.notifications.length > 0 && (
        <div className="dossier-notifications vl-col--1-1">
          {map(this.props.notifications, (notification, key) => (
            <Notification
              notification={notification}
              class="dossier-notification"
              key={key}
            />
          ))}
        </div>
      )
    );
  }

  renderActiveTasks() {
    if (this.props.loadingDossierDetails) {
      return (
        <p>{this.context.gettext("Loading new dossier information...")}</p>
      );
    }

    const activeTasks = this.props.getMyActiveTasksInDossier();
    return (
      activeTasks &&
      activeTasks.length > 0 && (
        <div className="dossier-active-tasks vl-col--1-1">
          <ActiveTasks
            activeTasks={activeTasks}
            execute={this.props.executeTask}
          />
        </div>
      )
    );
  }

  renderEnabledTasks() {
    const { enabledTasks } = this.props;

    if (!enabledTasks || isEmpty(enabledTasks)) return null;

    function mapActivityToTask(activity) {
      return {
        id: activity.id,
        name: activity.activityName,
        description: activity.activityDescription,
      };
    }

    const mappedEnabledTasks = map(enabledTasks, mapActivityToTask);

    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={2} title="Dossier Acties" iconClass="vl-vi-cursor-hand" />
        <ActiveTasks
          activeTasks={mappedEnabledTasks}
          execute={this.props.handleEnabledTaskSelect}
        />
      </div>
    );
  }

  renderDossierInformation() {
    return (
      this.props.dossierInformation &&
      this.props.dossierInformation.length > 0 && (
        <div className="dossier-information vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={this.context.gettext("Dossier details")}
              iconClass="vl-vi-news"
            />
            <InfoBlock
              infoBlockData={this.props.dossierInformation}
              columnsNumber={this.props.dossierInformationNbColumns}
            />
          </section>
        </div>
      )
    );
  }

  renderDownloads() {
    return (
      this.props.downloads &&
      this.props.downloads.length > 0 && (
        <div className="dossier-downloads vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={this.context.gettext("Documents and Downloads")}
              iconClass="vl-vi-file-download"
            />
            <FileDisplay downloads={this.props.downloads} />
          </section>
        </div>
      )
    );
  }

  renderContactInformation() {
    return (
      this.props.contactInformation && (
        <div className="dossier-contact vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={this.context.gettext("Contact")}
              iconClass="vl-vi-chat"
            />
            <Contact contactInformation={this.props.contactInformation} />
          </section>
        </div>
      )
    );
  }

  renderActivateTaskWarningModal() {
    return (
      <Modal
        open={this.props.shouldShowActivateTaskWarningModal}
        text={this.context.gettext(
          "Ben je zeker dat je deze actie wilt uitvoeren?",
        )}
        title={`${this.context.gettext("Voer")} ${(
          this.props.modalTask.activityName || ""
        ).toLowerCase()} ${this.context.gettext("uit")}`}
        yesText={this.context.gettext("Yes")}
        noText={this.context.gettext("No")}
        yes={() => this.props.toEnabledTask(this.props.modalTask.id)}
        no={() => this.props.cancelActivationOfEnabledTask()}
        modalIdentifier={this.props.modalTask.id || "default"}
      />
    );
  }

  renderSignersTable() {
    const tableData = this.props.getFieldFromDocument("fbu_ondertekenaars", [
      "signers",
      "elements",
    ])
      ? this.props
          .getFieldFromDocument("fbu_ondertekenaars", ["signers", "elements"])
          .map((signer) => {
            const party = get(signer, ["party", "selectedOption"]);
            const signerIsOCMW =
              party === roleKeys.OCMW_ALGEMEEN_DIRECTEUR ||
              party === roleKeys.OCMW_ONDERTEKENAAR;
            if (signer.signed) {
              return {
                ...signer,
                date: moment(signer.date).format("DD-MM-YYYY"),
              };
            } else if (!signer.signed && signerIsOCMW) {
              return { ...signer, date: "-", name: "-" };
            }
            return { ...signer, date: "-" };
          })
      : [];

    const tableColumns = [
      {
        key: "document",
        text: "Document",
        formatter: (document) => {
          return get(document, ["selectedOptionLabel", "nl"]);
        },
      },
      {
        key: "party",
        text: "Partij",
        formatter: (party) => {
          return get(party, ["selectedOptionLabel", "nl"]);
        },
      },
      {
        key: "name",
        text: "Ondertekenaar",
      },
      {
        key: "date",
        text: "Datum van ondertekening",
      },
    ];

    return (
      <div className="fl-signers-table">
        <span className="vl-description-data__label">
          Digitale ondertekeningen
        </span>
        <div>
          <Table
            tableData={tableData}
            tableColumns={tableColumns}
            noDataText="Geen ondertekeningen beschikbaar"
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <PageContent contentName="dossier">
        <div className="dossier-page-header">
          <div className="dossier-page-header-title">
            {this.props.shouldShowTitle && this.renderTitle()}
          </div>
        </div>
        {this.renderActivateTaskWarningModal()}
        {this.props.shouldShowNotifications && this.renderNotification()}
        {this.props.shouldShowActiveTasks && this.renderActiveTasks()}
        {this.renderEnabledTasks()}
        {this.props.shouldShowDossierInformation &&
          this.renderDossierInformation()}
        {this.renderSignersTable()}
        {this.props.shouldShowDownloads && this.renderDownloads()}
        {this.props.shouldShowContactInformation &&
          this.renderContactInformation()}
      </PageContent>
    );
  }
}

export default coreDossierWrapper(flDossierWrapper());
