import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get, keyBy, debounce, omit } from "lodash";
import moment from "moment";

import {
  createDossier,
  fetchDossierDetails,
  fetchDossierExecutableTasks,
} from "@skryv/core-react/src/core/store/actions/dossiers";
import { changeTaskAssignee } from "@skryv/core-react/src/core/store/actions/tasks";

import { fetchAllDossierDefinitions } from "@skryv/core-react/src/core/store/actions/dosdefs";
import { selectAllDossierDefinitions } from "@skryv/core-react/src/core/store/selectors/dosdefs";
import { searchDashboardDossiers } from "../store/actions";
import { selectDossierList } from "@skryv/core-react/src/core/store/selectors/dossiers";
import { selectCurrentUser } from "@skryv/core-react/src/core/store/selectors/users";

import { externalProps as buttonProps } from "@skryv/core-react/src/components/base/Button/Button";
import { externalProps as contactProps } from "@skryv/core-react/src/components/base/Contact/Contact";
import { externalProps as tableProps } from "@skryv/core-react/src/components/base/Table/Table";

import {
  getCreationModeKeyForDosdef,
  createNewDossier,
} from "@skryv/core-react/src/services/dossierCreation";
import router from "@skryv/core-react/src/services/router";
import { pageNames } from "@skryv/core-react/src/config/pages";

import authentication from "@skryv/core-react/src/services/authentication";
import { selectProfileTypeFromFlProfile } from "../store/selectors";
import profileTypes from "../constants/profileTypes";

// props that configure the content component
export const externalProps = {
  title: PropTypes.string,
  contactInformation: PropTypes.shape(contactProps),
  goToDossierInformationPageFirst: PropTypes.bool,
  createDossierInformation: PropTypes.arrayOf(
    PropTypes.shape({
      dosdefKey: PropTypes.string.isRequired,
      label: PropTypes.string,
      subLabel: PropTypes.string,
      body: PropTypes.elementType,
      buttonLabels: PropTypes.shape(buttonProps.labels),
      buttonIcon: buttonProps.iconClass,
      buttonPosition: buttonProps.iconPosition,
    }),
  ),
  myDossierColumns: tableProps.tableColumns,
  getCreationModeKeyForDosdef: PropTypes.func,
  redirectToFirstTaskAfterDossierCreation: PropTypes.bool,
  shouldShowTitle: PropTypes.bool,
  shouldShowCreateDossiers: PropTypes.bool,
  shouldShowMyDossiers: PropTypes.bool,
  shouldShowContactInformation: PropTypes.bool,
  loadingDossiers: PropTypes.bool,
};
// props that will be added to the content component by the wrapper function
// these are mostly redux variables, help functions and state parameters that can be used in the render functions of the content component
const reduxProps = {
  dosdefs: PropTypes.object,
  myDossiers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  createDossier: PropTypes.func,
  fetchAllDossierDefinitions: PropTypes.func,
  searchDashboardDossiers: PropTypes.func,
};
export const internalProps = {
  ...reduxProps,
  creatingDossier: PropTypes.object,
  toDossier: PropTypes.func,
  toDossierInformation: PropTypes.func,
  filterActiveTasks: PropTypes.func,
};
export const defaultProps = {
  title: "ePortal",
  goToDossierInformationPageFirst: false,
  myDossierColumns: [
    {
      key: "label",
      text: "Dossier",
    },
    {
      key: "dossierDefinition.label",
      text: "Dossier type",
    },
    {
      key: "createdAt",
      text: "Created at",
      formatter: (cell) => {
        // both cell and formatter are available
        return moment(cell).format("DD-MM-YYYY");
      },
    },
    {
      key: "tasks",
      text: "Required actions",
    },
  ],
  getCreationModeKeyForDosdef,
  redirectToFirstTaskAfterDossierCreation: true,
  shouldShowTitle: true,
  shouldShowCreateDossiers: true,
  shouldShowMyDossiers: true,
  shouldShowContactInformation: true,
};

const DEFAULT_MAX_DOSSIER_PER_PAGE = 3;
const OCMW_MAX_DOSSIER_PER_PAGE = 10;

export function dashboardPageWrapper(DashboardContent) {
  class DashboardPageWrapper extends React.Component {
    static propTypes = { ...externalProps, ...reduxProps };
    static defaultProps = defaultProps;

    constructor(props) {
      super(props);

      if (this.props.createDossierInformation) {
        // if any dossiers can be created, fetch all dossier definitions so we can check if they exists
        this.props.fetchAllDossierDefinitions();
      }

      this.state = {
        creatingDossier: {},
        loadingDossiers: true,
        itemsPerPage: props.isOCMW
          ? OCMW_MAX_DOSSIER_PER_PAGE
          : DEFAULT_MAX_DOSSIER_PER_PAGE,
      };

      this.createNewDossier = this.createNewDossier.bind(this);
      this.toDossierInformation = this.toDossierInformation.bind(this);
      this.filterActiveTasks = this.filterActiveTasks.bind(this);
      this.filterDossiersList = this.filterDossiersList.bind(this);
    }

    componentDidMount() {
      if (this.props.myDossierColumns) {
        // if we want to show the dossier list, fetch all active dossiers the current user is authorized to see
        authentication.profile().then(() => {
          this.props
            .searchDashboardDossiers("", 0, this.state.itemsPerPage)
            .then(() => this.setState({ loadingDossiers: false }));
        });
      }
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.isOCMW !== this.props.isOCMW &&
        this.props.myDossierColumns
      ) {
        authentication.profile().then(() => {
          let itemsPerPage = DEFAULT_MAX_DOSSIER_PER_PAGE;
          if (this.props.isOCMW) {
            itemsPerPage = OCMW_MAX_DOSSIER_PER_PAGE;
          }
          this.props
            .searchDashboardDossiers("", 0, itemsPerPage)
            .then(() =>
              this.setState({ loadingDossiers: false, itemsPerPage }),
            );
        });
      }
    }

    filterActiveTasks(tasks) {
      return tasks.filter(
        (task) =>
          task.active &&
          task.assignee === get(this.props, ["currentUser", "sub"]),
      );
    }

    createNewDossier(dosdefKey) {
      if (!this.props.dosdefs) {
        console.error(
          "Could not create dossier, the dossier definition has not been loaded yet",
        );
        return;
      }
      const dosdef = this.props.dosdefs[dosdefKey];
      if (!dosdef) {
        console.error(
          "Could not create dossier, the dossier definition has not been loaded yet",
        );
        return;
      }

      const creatingDossier = Object.assign({}, this.state.creatingDossier, {
        [dosdefKey]: true,
      });
      this.setState({ creatingDossier });
      createNewDossier(
        dosdef,
        this.props.createDossier,
        this.props.fetchDossierExecutableTasks,
        this.props.fetchDossierDetails,
        this.props.currentUser,
      ).finally(() =>
        this.setState({ creatingDossier: omit(creatingDossier["dosdefKey"]) }),
      );
    }

    toDossier(dossierId) {
      router.goToState(pageNames.DOSSIER, { id: dossierId });
    }

    filterDossiersList = debounce((searchQuery) => {
      this.setState({ loadingDossiers: true });
      this.props
        .searchDashboardDossiers(searchQuery, 0, this.state.itemsPerPage)
        .then(() => this.setState({ loadingDossiers: false }));
    }, 500);

    toDossierInformation(dosdefKey) {
      router.goToState(pageNames.DOSSIER_INFO, {
        id: get(this.props, ["dosdefs", dosdefKey, "id"]),
      });
    }

    render() {
      const componentsToPass = {
        ...this.props,
        creatingDossier: this.state.creatingDossier,
        createNewDossier: this.createNewDossier,
        toDossier: this.toDossier,
        toDossierInformation: this.toDossierInformation,
        filterDossiersList: this.filterDossiersList,
        filterActiveTasks: this.filterActiveTasks,
        loadingDossiers: this.state.loadingDossiers,
        itemsPerPage: this.state.itemsPerPage,
      };
      return <DashboardContent {...componentsToPass} />;
    }
  }

  const mapStateToProps = (state) => ({
    dosdefs: keyBy(selectAllDossierDefinitions(state), "key"),
    myDossiers: selectDossierList(state, "DASHBOARD"),
    currentUser: selectCurrentUser(state),
    isOCMW: selectProfileTypeFromFlProfile(state) === profileTypes.OCMW,
  });

  const mapDispatchToProps = {
    createDossier,
    changeTaskAssignee,
    fetchAllDossierDefinitions,
    searchDashboardDossiers,
    fetchDossierDetails,
    fetchDossierExecutableTasks,
  };

  return connect(mapStateToProps, mapDispatchToProps)(DashboardPageWrapper);
}
