import { fetchDossiers } from "@skryv/core-react/src/core/store/actions/search";

export function fetchFlProfile() {
  return {
    type: "FETCH_FL_PROFILE",
    api: {
      method: "GET",
      url: "api/fl/profile",
      cache: true,
    },
  };
}

export function searchDashboardDossiers(
  searchQuery = "",
  pageNumber = 0,
  itemsPerPage = 10,
) {
  const query = {
    query: {
      bool: {
        filter: [
          {
            query_string: {
              query: `*${searchQuery}*`,
              default_operator: "AND",
            },
          },
          {
            bool: {
              should: [
                {
                  term: {
                    type: "dossier",
                  },
                },
              ],
            },
          },
        ],
        must_not: [
          { term: { "dossier.dossierDefinition.key": "fbu_ocmw_gegevens" } },
        ],
      },
    },
    sort: {
      "dossier.latestActivity": {
        order: "desc",
        nested: { path: "dossier" },
      },
    },
    from: pageNumber * itemsPerPage,
    size: itemsPerPage,
  };

  return fetchDossiers({ listKey: "DASHBOARD", query });
}

export function fetchOcmwDossier() {
  const query = {
    from: 0,
    query: {
      bool: {
        filter: [
          {
            nested: {
              path: "dossier",
              query: {
                term: { "dossier.dossierDefinition.key": "fbu_ocmw_gegevens" },
              },
            },
          },
          {
            bool: {
              should: [{ term: { type: "dossier" } }],
            },
          },
        ],
      },
    },
    size: 1,
  };
  return {
    type: "FETCH_OCMW_DOSSIER",
    api: {
      url: "api/elasticsearch/search",
      method: "POST",
      data: query,
    },
  };
}

export const FETCH_FL_APPLICATION_CONFIGURATION =
  "FETCH_FL_APPLICATION_CONFIGURATION";
export const FETCH_FL_APPLICATION_CONFIGURATION_RESPONSE = `${FETCH_FL_APPLICATION_CONFIGURATION}/RESPONSE`;
export const FETCH_FL_APPLICATION_CONFIGURATION_REQUEST = `${FETCH_FL_APPLICATION_CONFIGURATION}/REQUEST`;
export const FETCH_FL_APPLICATION_CONFIGURATION_ERROR = `${FETCH_FL_APPLICATION_CONFIGURATION}/ERROR`;
export function fetchFlConfiguration() {
  return {
    type: "FETCH_FL_APPLICATION_CONFIGURATION",
    api: {
      method: "GET",
      url: "api/fl/configuration",
      cache: true,
    },
  };
}

export function fetchGemeenteParameters(postalCode, dossierType) {
  return {
    type: "FETCH_PARAMETERS",
    api: {
      url: `api/gemeente-parameters?postalCode=${postalCode}&dossierType=${dossierType}`,
      method: "GET",
    },
  };
}

export function fetchSubdossiersOfHoofddossier(
  hoofddossierId,
  formulier,
  page,
  size,
) {
  return {
    type: "FETCH_SUBDOSSIERS_OF_HOOFDDOSSIER",
    api: {
      url: `api/subdossiers?hoofddossierId=${hoofddossierId}&formulier=${formulier}${page !== null && size !== null ? `&page=${page}&size=${size}` : ""}`,
      method: "GET",
    },
  };
}

export function fetchInformatieFromHoofddossier(hoofddossierId, dossierType) {
  return {
    type: "FETCH_CONTACT_INFO",
    api: {
      url: `api/subdossiers/informatie/${hoofddossierId}?dossierType=${dossierType}`,
      method: "GET",
    },
  };
}

export function fetchContactInformatieForGemeente(nisCode, dossierType) {
  return {
    type: "FETCH_CONTACT_INFO_GEMEENTE",
    api: {
      url: `api/gemeente-parameters/${nisCode}/contact?dossierType=${dossierType}`,
      method: "GET",
    },
  };
}
