export const SHOW_VGW_DOSSIER_BUTTON = false;
export const SHOW_HP_DOSSIER_BUTTON = true;
export const SHOW_HP_WACHTTIJD_BUTTON = true;
export const SHOW_HS_DOSSIER_BUTTON = true;
export const SHOW_OVRE_DOSSIER_BUTTON = false;
export const SHOW_NW_DOSSIER_BUTTON = true;
export const SHOW_OVRE_GAS_DOSSIER_BUTTON = false;
export const SHOW_HL_DOSSIER_BUTTON = false;
export const SHOW_VGWVHR_DOSSIER_BUTTON = true;
export const SHOW_GV_DOSSIER_BUTTON = true;
export const SHOW_DTL_DOSSIER_BUTTON = true;
export const SHOW_VLOK_DOSSIER_BUTTONS = true;

// NOTE: do not change this
// this boolean automagically returns true if all other booleans are set to false
export const HIDE_ALL_DOSSIER_BUTTONS =
  !SHOW_VGW_DOSSIER_BUTTON &&
  !SHOW_HP_DOSSIER_BUTTON &&
  !SHOW_HP_WACHTTIJD_BUTTON &&
  !SHOW_HS_DOSSIER_BUTTON &&
  !SHOW_OVRE_DOSSIER_BUTTON &&
  !SHOW_NW_DOSSIER_BUTTON &&
  !SHOW_OVRE_GAS_DOSSIER_BUTTON &&
  !SHOW_HL_DOSSIER_BUTTON &&
  !SHOW_VGWVHR_DOSSIER_BUTTON &&
  !SHOW_GV_DOSSIER_BUTTON &&
  !SHOW_DTL_DOSSIER_BUTTON &&
  !SHOW_VLOK_DOSSIER_BUTTONS;
