import React from "react";
import PropTypes from "prop-types";
import MaxLengthTableItem from "./MaxLengthTableItem";

const maxSizeTableColumns = ["verdiep", "kamernummer", "locatie"];

export class CustomTableContent extends React.Component {
  static propTypes = {
    pageable: PropTypes.shape({
      pageNumber: PropTypes.number,
      pageSize: PropTypes.number,
    }),
    totalPages: PropTypes.number,
    tableData: PropTypes.array,
    tableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string,
        formatter: PropTypes.func,
      }),
    ),
    itemClickAction: PropTypes.func,
    renderCell: PropTypes.func,
    gettext: PropTypes.func,
  };

  render() {
    console.log(this.props.tableData);
    console.log(this.props.tableColumns);
    return this.props.tableData.map((record) => (
      <tr
        role="button"
        tabIndex="0"
        key={record.id}
        onKeyPress={() =>
          this.props.itemClickAction && this.props.itemClickAction(record)
        }
        onClick={() =>
          this.props.itemClickAction && this.props.itemClickAction(record)
        }
      >
        {this.props.tableColumns.map((column) => (
          <td
            data-title={this.props.gettext(column.text)}
            key={`${column.key}-${column.text}`}
          >
            {maxSizeTableColumns.includes(column.key) ? (
              <MaxLengthTableItem value={record[column.key]} />
            ) : (
              record[column.key]
            )}
          </td>
        ))}
      </tr>
    ));
  }
}
