import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CoreHeader from "@skryv/core-react-vo/src/components/layout/Header/Header";

import { loginUser } from "@skryv/core-react/src/core/store/actions/authentication";
import { fetchConfiguration } from "@skryv/core-react/src/core/store/actions/configuration";
import { isAuthenticated } from "@skryv/core-react/src/core/store/selectors/users";
import { isInIframe } from "@skryv/core-react/src/core/util/iframe-utilities";

import { postscribePromise } from "../services/postscribe";

import { fetchFlConfiguration } from "../store/actions";
import {
  hasLoadedBurgerprofiel,
  selectBurgerprofielHeader,
} from "../store/selectors";

import contextType from "@skryv/core-react/src/services/contextTypes";

const reduxProps = {
  isAuthenticated: PropTypes.bool,
  hasLoadedBurgerprofiel: PropTypes.bool,
  burgerprofielHeaderEmbedCode: PropTypes.string,
  loginUser: PropTypes.func,
  fetchConfiguration: PropTypes.func,
  fetchFlConfiguration: PropTypes.func,
};

class flHeader extends React.Component {
  static propTypes = reduxProps;
  static contextType = contextType;

  constructor(props) {
    super(props);

    props.loginUser();
    props.fetchConfiguration();
    props.fetchFlConfiguration();

    this.state = {
      title: "Wonen in Vlaanderen - ",
      subtitle: "eportal",
      hasInitializedHeaderScript: false,
      headerSelector: "#header-script",
      shouldShowProfile: false,
    };
  }

  componentDidUpdate() {
    this.loadMijnBurgerprofielHeader();
  }

  loadMijnBurgerprofielHeader() {
    // if we are running in the MBP iframe, don't show the header
    if (isInIframe) return;

    if (
      this.state.hasInitializedHeaderScript ||
      !this.props.hasLoadedBurgerprofiel
    ) {
      return;
    }

    if (
      !this.props.isAuthenticated ||
      !this.props.burgerprofielHeaderEmbedCode
    ) {
      this.loadFallbackHeader();
      return;
    }

    const burgerprofielPolyfillSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js";
    const burgerprofielClientSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-client/dist/index.js";

    return Promise.all([
      postscribePromise(this.state.headerSelector, burgerprofielPolyfillSrc),
      postscribePromise(this.state.headerSelector, burgerprofielClientSrc),
    ])
      .then(() =>
        postscribePromise(
          this.state.headerSelector,
          this.props.burgerprofielHeaderEmbedCode,
        ),
      )
      .then(() => {
        // configure the Mijn Burgerprofiel header
        window.vl.widget.client.capture((widget) => {
          widget.getExtension("citizen_profile.session").then((session) => {
            session.configure({
              active: this.props.isAuthenticated,
              endpoints: {
                loginUrl: "/api/login/frontoffice",
                logoutUrl: "/api/logout",
                switchCapacityUrl: "/api/wisselen-van-account",
              },
            });
          });
        });
      })
      .then(() => this.setState({ hasInitializedHeaderScript: true }));
  }

  loadFallbackHeader() {
    const voHeaderEmbedCode =
      "//widgets.vlaanderen.be/widget/live/5e620515299644f1978ddd657708741a";
    postscribePromise(this.state.headerSelector, voHeaderEmbedCode).then(() =>
      this.setState({
        hasInitializedHeaderScript: true,
        shouldShowProfile: true,
      }),
    );
  }

  render() {
    if (isInIframe) return null;

    return (
      <div>
        <div id="header-script"></div>
        <CoreHeader {...this.props} {...this.state}></CoreHeader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  hasLoadedBurgerprofiel: hasLoadedBurgerprofiel(state),
  burgerprofielHeaderEmbedCode: selectBurgerprofielHeader(state),
});

const mapDispatchToProps = {
  loginUser,
  fetchConfiguration,
  fetchFlConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(flHeader);
