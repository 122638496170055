import React from "react";
import {
  contactWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/base/Contact/Contact";
import contextType from "@skryv/core-react/src/services/contextTypes";

class CustomContact extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  render() {
    return (
      <section
        className="vl-contact-card"
        itemScope="itemscope"
        itemType="http://schema.org/GovernmentOrganization"
      >
        <h1 className="vl-contact-card__title" itemProp="name">
          {this.context.gettext(this.props.contactInformation.title)}
        </h1>
        <div className="vl-contact-card__content-wrapper">
          <div className="vl-contact-card__content">
            <div className="vl-contact-card__data">
              <dl>
                {this.props.contactInformation.address && (
                  <>
                    <dt className="vl-contact-card__data__title">
                      {this.context.gettext("Address")}
                    </dt>
                    <dd className="vl-contact-card__data__content">
                      <div
                        itemProp="address"
                        itemScope
                        itemType="http://schema.org/PostalAddress"
                      >
                        <div>
                          {this.props.contactInformation.address
                            .streetAndNumber && (
                            <span itemProp="streetAddress">
                              {this.context.gettext(
                                this.props.contactInformation.address
                                  .streetAndNumber,
                              )}
                            </span>
                          )}
                        </div>
                        <div>
                          {this.props.contactInformation.address.zipCode && (
                            <span itemProp="postalCode">
                              {this.context.gettext(
                                this.props.contactInformation.address.zipCode,
                              )}{" "}
                            </span>
                          )}
                          {this.props.contactInformation.address.city && (
                            <span itemProp="addressLocality">
                              {this.context.gettext(
                                this.props.contactInformation.address.city,
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </dd>
                  </>
                )}
                {this.props.contactInformation.phone && (
                  <>
                    <dt className="vl-contact-card__data__title">
                      {this.context.gettext("Telephone")}
                    </dt>
                    <dd
                      itemProp="telephone"
                      className="vl-contact-card__data__content"
                    >
                      <a href={`tel:${this.props.contactInformation.phone}`}>
                        {this.props.contactInformation.phone}
                      </a>
                    </dd>
                  </>
                )}
                {this.props.contactInformation.email && (
                  <>
                    <dt className="vl-contact-card__data__title">
                      {this.context.gettext("Email")}
                    </dt>
                    <dd className="vl-contact-card__data__content">
                      <a
                        href={`mailto:${this.props.contactInformation.email}`}
                        itemProp="email"
                      >
                        {this.props.contactInformation.email}
                      </a>
                    </dd>
                  </>
                )}
                {this.props.contactInformation.website && (
                  <>
                    <dt className="vl-contact-card__data__title">
                      {this.context.gettext("Website")}
                    </dt>
                    <dd className="vl-contact-card__data__content">
                      <a
                        href={`https://${this.props.contactInformation.website.replace("https://", "")}`}
                        itemProp="email"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {this.props.contactInformation.website}
                      </a>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default contactWrapper(CustomContact);
