import React from "react";
import { get } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import ActiveTasks from "@skryv/core-react-vo/src/components/dossier/ActiveTasks/ActiveTasks";
import contactInformation from "../constants/contactInformation";
import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";
import InfoBlock from "@skryv/core-react-vo/src/components/base/InfoBlock/InfoBlock";
import Contact from "@skryv/core-react-vo/src/components/base/Contact/Contact";
import FileDisplay from "@skryv/core-react-vo/src/components/base/FileDisplay/FileDisplay";

export function flDossierWrapper() {
  class flDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      this.state = {
        contactInformation: {
          ...contactInformation,
          email: undefined,
          title:
            "Voor meer info contacteer de woonmaatschappij in uw gemeente.",
          address: undefined,
          lat: undefined,
          long: undefined,
          mapUrl: undefined,
          phone: undefined,
        },
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if (this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier, "label"),
          label: this.context.gettext("Dossier label"),
        });

        if (get(this.props.dossier, "externalId") !== undefined) {
          dossierInformation.push({
            value: get(this.props.dossier, "externalId"),
            label: this.context.gettext("Dossiernummer"),
          });
        }

        if (this.props.dossierDetails) {
          dossierInformation.push({
            value: moment(
              get(this.props.dossierDetails, ["dossier", "latestActivity"]),
            ).format("DD-MM-YYYY"),
            label: this.context.gettext("Last update"),
          });

          const maandenOnonderbrokenIngeschreven =
            this.props.getFieldFromDocument("huurpremieWachtijdAanvraag", [
              "maandenOnonderbrokenIngeschreven",
            ]);
          if (
            maandenOnonderbrokenIngeschreven !== undefined &&
            maandenOnonderbrokenIngeschreven !== 0
          ) {
            dossierInformation.push({
              value: maandenOnonderbrokenIngeschreven,
              label:
                "Aantal maanden ononderbroken ingeschreven bij de woonmaatschappij in uw gemeente",
            });
          }

          const aanvraagMokelijkVanaf = this.props.getFieldFromDocument(
            "huurpremieWachtijdAanvraag",
            ["aanvraagMokelijkVanaf"],
          );
          if (aanvraagMokelijkVanaf !== undefined) {
            dossierInformation.push({
              value: aanvraagMokelijkVanaf,
              label: "Huurpremie aanvraag mogelijk vanaf:",
            });
          }

          const remarks = this.props.getFieldFromDocument(
            "huurpremieWachtijdAanvraag",
            ["hpwtOpmerkingenHidden", "value"],
          );
          if (remarks !== undefined) {
            dossierInformation.push({
              value: remarks,
              label: "U vroeg het invulformulier op papier te verkrijgen.",
            });
          }
        }
      }
      return dossierInformation;
    }

    getDownloads() {
      const downloads = [];
      if (this.props.downloads.length > 0) {
        downloads.push(this.props.downloads[0]);
      }
      return downloads;
    }

    render() {
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
      };

      return <HPWTDossierPage {...componentsToPass} />;
    }
  }

  return flDossier;
}

class HPWTDossierPage extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  renderTitle() {
    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={1} title="Huurpremie wachttijd" />
      </div>
    );
  }

  renderActiveTasks() {
    if (this.props.loadingDossierDetails) {
      return (
        <p>{this.context.gettext("Loading new dossier information...")}</p>
      );
    }

    const activeTasks = this.props.getMyActiveTasksInDossier();
    return (
      activeTasks &&
      activeTasks.length > 0 && (
        <div className="dossier-active-tasks vl-col--1-1">
          <ActiveTasks
            activeTasks={activeTasks}
            execute={this.props.executeTask}
          />
        </div>
      )
    );
  }

  renderDossierInformation() {
    return (
      this.props.dossierInformation &&
      this.props.dossierInformation.length > 0 && (
        <div className="dossier-information vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={"Overzicht van uw aanvraag"}
              iconClass="vl-vi-news"
            />
            <InfoBlock
              infoBlockData={this.props.dossierInformation}
              columnsNumber={this.props.dossierInformationNbColumns}
            />
          </section>
        </div>
      )
    );
  }

  renderDownloads() {
    return (
      this.props.downloads &&
      this.props.downloads.length > 0 && (
        <div className="dossier-downloads vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={"Bekijk het resultaat van je aanvraag"}
              iconClass="vl-vi-file-download"
            />
            <FileDisplay downloads={this.props.downloads} />
          </section>
        </div>
      )
    );
  }

  renderContactInformation() {
    return (
      this.props.contactInformation && (
        <div className="dossier-contact vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={this.context.gettext("Contact")}
              iconClass="vl-vi-chat"
            />
            <Contact contactInformation={this.props.contactInformation} />
          </section>
        </div>
      )
    );
  }

  render() {
    return (
      <PageContent contentName="dossier">
        <div className="dossier-page-header">
          <div className="dossier-page-header-title">
            {this.props.shouldShowTitle && this.renderTitle()}
          </div>
        </div>
        {this.props.shouldShowActiveTasks && this.renderActiveTasks()}
        {this.props.shouldShowDossierInformation &&
          this.renderDossierInformation()}
        {this.props.shouldShowDownloads && this.renderDownloads()}
        {this.props.shouldShowContactInformation &&
          this.renderContactInformation()}
      </PageContent>
    );
  }
}

export default coreDossierWrapper(flDossierWrapper());
