import React from "react";
import PropTypes from "prop-types";

import contextType from "@skryv/core-react/src/services/contextTypes";

import CustomInfloBlockItem from "./CustomInfloBlockItem";

class CustomInfoBlock extends React.Component {
  static propTypes = {
    infoBlockData: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.element,
          PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.element,
              ]),
              label: PropTypes.string,
              truncate: PropTypes.bool,
            }),
          ),
        ]),
        label: PropTypes.string,
        nested: PropTypes.bool,
        truncate: PropTypes.bool,
      }),
    ),
    columnsNumber: PropTypes.number,
  };
  static defaultProps = {
    infoBlockData: [],
    columnsNumber: 2,
  };
  static contextType = contextType;

  render() {
    return (
      this.props.infoBlockData.length > 0 && (
        <div className="vl-description-data">
          <dl className="vl-grid vl-grid--is-stacked">
            {this.props.infoBlockData.map((item) => (
              <CustomInfloBlockItem
                key={item.label}
                item={item}
                columnsNumber={this.props.columnsNumber}
              />
            ))}
          </dl>
        </div>
      )
    );
  }
}

export default CustomInfoBlock;
