import React from "react";
import { get, map } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";

import contactInformation from "../constants/contactInformation";

import milestoneDefKeys from "../constants/milestoneDefinitionKeys"; // import milestone definition keys

export function flDossierWrapper() {
  class flDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation,
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.props.notifications,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
          notifications: this.getNotifications(),
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if (this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier, "label"),
          label: this.context.gettext("Dossier label"),
        });
        dossierInformation.push({
          value: moment(
            get(this.props.dossierDetails, ["dossier", "latestActivity"]),
          ).format("DD-MM-YYYY"),
          label: this.context.gettext("Last update"),
        });
      }
      if (this.props.dossierDetails) {
        const requester = this.props.getFieldFromDocument("vgw_aanvraag", [
          "leners",
          "elements",
          0,
          "rrn_naam",
        ]);
        const hasRequester =
          requester && requester.naam && requester.rijksregisternummer;
        const prettifiedRequester = hasRequester
          ? requester.voornaam +
            " " +
            requester.naam +
            " (" +
            this.props.prettyRijksregisternummer(
              requester.rijksregisternummer,
            ) +
            ")"
          : "";
        dossierInformation.push({
          value: prettifiedRequester,
          label: "Hoofdaanvrager",
        });

        const coRequesters = map(
          this.props.getFieldFromDocument("vgw_aanvraag", [
            "leners",
            "elements",
          ]),
          (value, index) => {
            if (index === 0) return "";
            return (
              this.props.getFieldFromDocument("vgw_aanvraag", [
                "leners",
                "elements",
                index,
                "rrn_naam",
                "voornaam",
              ]) +
              " " +
              this.props.getFieldFromDocument("vgw_aanvraag", [
                "leners",
                "elements",
                index,
                "rrn_naam",
                "naam",
              ]) +
              " (" +
              this.props.prettyRijksregisternummer(
                this.props.getFieldFromDocument("vgw_aanvraag", [
                  "leners",
                  "elements",
                  index,
                  "rrn_naam",
                  "rijksregisternummer",
                ]),
              ) +
              ")\n"
            );
          },
        );
        dossierInformation.push({
          value: coRequesters,
          label: "Mede-aanvragers",
        });
      }
      return dossierInformation;
    }

    getDownloads() {
      const downloads = [];
      if (this.props.getMilestone("vgw_dossier_geexporteerd")) {
        downloads.push(
          this.props.mapDocumentToPdfDownloadInfo(
            this.props.getDocument("vgw_aanvraag"),
            "vgw_aanvraag",
            "vgw_aanvraag",
          ),
        );
      }
      return downloads;
    }

    getNotifications() {
      const milestone_aanvraag_ingevuld = this.props.getMilestone(
        milestoneDefKeys.VGW_AANVRAAG_INGEVULD,
      );
      const milestone_aanvraag_geannuleerd = this.props.getMilestone(
        milestoneDefKeys.VGW_AANVRAAG_GEANNULEERD,
      );
      const milestone_dossier_geexporteerd = this.props.getMilestone(
        milestoneDefKeys.VGW_DOSSIER_GEEXPORTEERD,
      );

      let notifications = [];
      if (milestone_aanvraag_geannuleerd) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Dossier geannuleerd"),
            message: this.context.gettext("De aanvraag werd geannuleerd."),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (!milestone_dossier_geexporteerd && milestone_aanvraag_ingevuld) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Dit dossier is nog niet volledig"),
            message: this.context.gettext(
              "Alle mede-aanvragers moeten de aanvraag bevestigen.",
            ),
            type: notificationTypes.WARNING,
          },
        ];
      }
      if (milestone_dossier_geexporteerd) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Dit dossier is ingediend"),
            message: `${this.context.gettext("Uw aanvraag kreeg het volgende dossiernummer:")} ${this.props.dossier.label}.
          ${this.context.gettext("Vermeld steeds dit dossiernummer bij eventuele vervolgcommunicaties.")}.`,
            type: notificationTypes.SUCCESS,
          },
        ];
      }

      return notifications;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
        notifications: this.state.notifications,
      };

      return <CoreDossierPage {...componentsToPass} />;
    }
  }

  return flDossier;
}

export default coreDossierWrapper(flDossierWrapper());
