import React from "react";
import PropTypes from "prop-types";
import { BeoordelingsCriteria } from "./BeoordelingsCriteria";
import moment from "moment/moment";

export const KotlabelInfo = (props) => {
  const beoordeling = getBeoordeling(props.getFieldFromDocument);

  return (
    <>
      {(beoordeling.kotlabelLink || beoordeling.vlaamsKotlabel) && (
        <BeoordelingsCriteria beoordeling={beoordeling} />
      )}
    </>
  );
};

KotlabelInfo.propTypes = {
  getFieldFromDocument: PropTypes.func.isRequired,
};

const getBeoordeling = (getFieldFromDocument) => {
  const kotlabelLink = getFieldFromDocument("kotlabel", ["linkKotlabel"]);
  const vlaamsKotlabel = mapToBeoordeling(
    getFieldFromDocument("kotlabel", ["vlaamsKotlabel"]),
  );
  const gemeentelijkeCriteria = mapToBeoordeling(
    getFieldFromDocument("kotlabel", ["gemeentelijkeCriteria"]),
  );

  return {
    kotlabelLink: kotlabelLink,
    vlaamsKotlabel: vlaamsKotlabel,
    gemeentelijkeCriteria: gemeentelijkeCriteria,
  };
};

const mapToBeoordeling = (beoordeling) => {
  if (!beoordeling.beoordeling) return undefined;

  const criteria =
    beoordeling.criteria &&
    beoordeling.criteria.elements &&
    beoordeling.criteria.elements.length > 0
      ? beoordeling.criteria.elements.map((criterium) => {
          return {
            criterium: criterium.criterium,
            beoordeling: criterium.beoordeling.selectedOption,
          };
        })
      : undefined;

  return {
    status: beoordeling.beoordeling.selectedOption,
    datumStatus: moment(
      moment.utc(beoordeling.datumBeoordeling).toDate(),
    ).format("DD-MM-YYYY"),
    criteria: criteria,
  };
};
