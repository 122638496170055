export const dossierStatus = {
  aanvraagIngediend: "Aanvraag ingediend",
  inBehandeling: "In behandeling",
  stopgezet: "Stopgezet",
  afgerond: "Afgerond",
  gunstig: "Gunstig",
  ongunstig: "Ongunstig",
  vervallen: "Vervallen",
  geen: "Geen",
  waarschuwing: "Waarschuwing",
};
